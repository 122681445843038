/**
 * 模块名称: 公司制度管理右侧弹窗 => 新建发布
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from 'api'
import {
	Form,
	Input,
	Select,
	Upload,
	message,
	Icon,
	Button,
	Row,
	Col
} from 'antd'
import BtnGroup from 'components/BtnGroup'
import { upLoadModule } from 'utils/common'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload
let disabled = true

const AddCorpRuleDrawer = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue } = props.form

	const { setVisible, getCorpRuleRuleList, editRowId, setAddCorpRulesLoading } = props

	const [fileList, setFileList] = useState([])
	const [choiceList, setChoiceList] = useState([])
	const [saveLoading, setSaveLoading] = useState(false)

	const [title, setTitle] = useState('')
	const [content, setContent] = useState('')
	const [scope, setScope] = useState([])

	const draggerRef = useRef(null)

	useEffect(() => {
		disabled = true
		setAddCorpRulesLoading(false)
		getCorpRuleGetEditRow()
	}, [])

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
	}
	//根据是否传值过来editRowId判断是上传文档还是编辑文档
	const getCorpRuleGetEditRow = () => {
		let params = {}
		if (editRowId) {
			setAddCorpRulesLoading(true)
			params.id = editRowId
			api.getCorpRuleGetEditRow(params).then(res => {
				setTitle(res.title)
				setContent(res.content)
				setScope(res.scope)

				api.getCorpRuleCompanyList().then(data => {
					setChoiceList(data.list)
					let ids = []
					data.list.forEach(item => {
						ids.push(item.id)
					})
					let scopeRes = res.scope.filter(item => ids.some(e => e === item))
					setScope(scopeRes)
				})
				if (res.affix.length) {
					res.affix.forEach((item, index) => {
						fileList.push({
							status: 'done',
							uid: index,
							name: item.name,
							url: item.url
						})
					})
					setFileList(res.affix.map((item, index) => ({...item, uid: index})))
				}
				//console.log(fileList)
				let timer = setTimeout(() => {
					setAddCorpRulesLoading(false)
					clearTimeout(timer)
				}, 500)

			}).catch(() => { setAddCorpRulesLoading(false) })
		}
	}

	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		if (disabled) {
			disabled = false
			let timer = setTimeout(() => {
				disabled = true
				clearTimeout(timer)
			}, 1000)
		} else {
			message.error('请不要频繁点击')
			return
		}
		setSaveLoading(true)
		validateFields((err, vals) => {
			//console.log(vals)
			let params = Object.assign({}, vals)
			let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
			params.affix = uploadList
			if (editRowId) params.id = editRowId
			if (!err) {
				api.onCorpRuleruleHandle(params).then(res => {
					message.success('操作成功')
					setVisible(false)
					setSaveLoading(false)
					getCorpRuleRuleList()
				})
			}
		}).catch(() => { setSaveLoading(false) })
	}

	// 推送范围
	const getCorpRuleCompanyList = () => {
		api.getCorpRuleCompanyList().then(res => {
			setChoiceList(res.list)
		})
	}
	//全选推送范围
	const selCorpAll = () => {
		api.getCorpRuleCompanyList().then(res => {
			let idArr = []
			res.list.forEach((item, index, arr) => {
				idArr.push(item.id)
			})
			setChoiceList(res.list)
			setFieldsValue({
				'scope': idArr
			})
		})
	}
	//清空推送范围
	const clearCorpAll = () => {
		setFieldsValue({
			'scope': []
		})
	}

	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .pptx, .PDF, .doc, .docx, .csv, .xlsx',
		allowSizeType: 6
  })

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<FormItem label="标题">
					{getFieldDecorator('title', {
						initialValue: title,
						rules: [{ required: true, message: '请输入标题' }]
					})(<Input autoComplete="off" placeholder="请输入标题" maxLength={50} />)}
				</FormItem>
				<FormItem label="内容">
					{getFieldDecorator('content', {
						initialValue: content,
						// rules: [{ required: true, message: '请输入内容' }]
					})(<Input.TextArea placeholder="请输入内容" maxLength={500} />)}
				</FormItem>
				<FormItem label="附件">
					<div className="dragger-box" ref={draggerRef}>
						<Dragger
							{...uploadFiles}
						>
							<p className="ant-upload-drag-icon">
								<Icon type="inbox" />
							</p>
							<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
							<p className="ant-upload-hint">
								附件支持格式：GIF、PNG、JPG、pptx、PDF、doc、docx、csv、xlsx  支持添加多个附件，单个附件大小不超过50M，文件数量不超过5个。
							</p>
						</Dragger>
					</div>
				</FormItem>
				<FormItem label="推送范围">
					<Row gutter={8}>
						<Col span={18}>
							{getFieldDecorator('scope', {
								initialValue: scope,
								rules: [{ required: true, message: '请选推送范围' }]
							})(
								<Select
									placeholder="请选择推送范围"
									mode="multiple"
									style={{ width: '100%' }}
									onDropdownVisibleChange={bool => bool && getCorpRuleCompanyList()}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{choiceList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
								</Select>
							)}
						</Col>
						<Col span={3}>
							<Button type="primary" onClick={selCorpAll}>全选</Button>
						</Col>
						<Col span={3}>
							<Button onClick={clearCorpAll}>清空</Button>
						</Col>
					</Row>
				</FormItem>
				<BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
			</Form>
		</>
	)
}
export default Form.create()(AddCorpRuleDrawer)