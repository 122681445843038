/**
 * 模块名称: 到款列表
 * @author wangchanghong@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
    Form,
    DatePicker,
    message,
    Input,
    Select
} from 'antd'

import BtnGroup from '@/components/BtnGroup'
import moment from "moment";

const Option = Select.Option
const { TextArea } = Input;
const FormItem = Form.Item
let editId = '' //编辑使用


let canEditToCompany = true



const Edit = (props) => {
    const { data, isNew, setVisible,dateFormat,getList } = props
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

    const [saveLoading, setSaveLoading] = useState(false)
    const [editTag, setEditTag] = useState('')
    const [editing, setEditing] = useState('')

    const [toCompanyList, setToCompanyList] = useState([])

    useEffect( () => {
        //获取当前登陆人的到款公司
        api.fundMoneyBackGetAllowCompanyList().then(
            (res) => {
                setToCompanyList(res.list)
            }
        )
        if(!isNew){
            editId = data.id
            setFieldsValue({
                toCompanyId: data.toCompanyId,
                bank: data.bank ,
                date: moment(data.date, dateFormat) ,
                name: data.name ,
                amount: data.amount ,
                account: data.account ,
                note: data.note
            })

            setEditTag('edit')
            canEditToCompany = false
        }else{
            editId = 0
            setFieldsValue({
                toCompanyId: undefined,
                bank: '' ,
                date: moment() ,
                name: '' ,
                amount: '' ,
                account: '' ,
                note: ''
            })
            setEditTag('new')
            canEditToCompany = true
        }
    }, [data, isNew])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        }
    }



    // 提交添加
    const formSubmit = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {
            if (!err) {
                setSaveLoading(true)
                if (editId) {
                    vals.date = moment(vals.date).format(dateFormat)
                    api.fundMoneyBackEdit({...vals, id:editId}).then(
                        () => {
                            setSaveLoading(false)
                            setVisible(false)
                            message.success('修改成功')
                            getList()
                        }
                    ).catch(() => setSaveLoading(false))
                }else{
                    api.fundMoneyBackAdd({...vals}).then(
                        () => {
                            setSaveLoading(false)
                            setVisible(false)
                            message.success('添加成功')
                            getList()
                        }
                    ).catch(() => setSaveLoading(false))
                }
            }
        })
    }

    const onBlurFunc = (o) => {
        if(isNew) return;
        setEditing('')
    }

    const onFocusFunc = (o) => {
        if(isNew) return;
        setEditing('editing')
    }

    return (
        <Form onSubmit={formSubmit} {...formItemLayout} className={editTag}>
            {
                canEditToCompany ? 
                    <FormItem label="到款公司">
                        {getFieldDecorator('toCompanyId', {
                            rules: [{ required: true, message: '请选择到款公司' }]
                        })(
                            <Select placeholder="请选择" style={{ width: 177 }}>
                                {toCompanyList.map(item => <Option key={item.id} value={item.id} >{item.name}</Option>)}
                            </Select>
                        )}
                    </FormItem>
                : <></>
            }
            <FormItem label="到款银行">
                {getFieldDecorator('bank', {
                    rules: [{ required: true, message: '请输入到款银行' }]
                })(<Input className={editing} onBlur={onBlurFunc} onFocus={onFocusFunc} placeholder="请输入" />)}
            </FormItem>
            <FormItem label="到款日期">
                {getFieldDecorator('date', {
                    rules: [{ required: true, message: '请输入到款日期' }]
                })(
                    <DatePicker className={editing} onBlur={onBlurFunc} onFocus={onFocusFunc} />
                )}
            </FormItem>
            <FormItem label="到款金额">
                {getFieldDecorator('amount', {
                    rules: [{ required: true, message: '请输入到款金额' }]
                })(
                    <Input className={editing} onBlur={onBlurFunc} onFocus={onFocusFunc} placeholder="请输入" />
                )}
            </FormItem>
            <FormItem label="付款方用户名">
                {getFieldDecorator('name')(
                    <Input className={editing} onBlur={onBlurFunc} onFocus={onFocusFunc} placeholder="请输入" />
                )}
            </FormItem>
            <FormItem label="付款方用账号">
                {getFieldDecorator('account')(
                    <Input className={editing} onBlur={onBlurFunc} onFocus={onFocusFunc} placeholder="请输入"  />
                )}
            </FormItem>
            <FormItem label="备注">
                {getFieldDecorator('note')(
                    <TextArea
                        className={editing} onBlur={onBlurFunc} onFocus={onFocusFunc}
                        placeholder="请输入"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                )}
            </FormItem>
            <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
        </Form>
    )
}

export default Form.create()(Edit)