/**
 * 模块名称: 日常办公审批 => 日常办公审批详情
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from 'api'
import {
	Steps,
	Descriptions,
	Button,
	Modal,
	Input,
	Icon,
	message,
	Spin
} from 'antd'
import { parseSearch } from 'utils'
import CryptoJS from 'crypto-js'

const { Step } = Steps
const dItem = Descriptions.item

let areaTxt = '', result = ''

const OfficeWorkDetail = (props) => {

	const { history, location } = props
  const search = parseSearch(props.location.search)
	const [detail, setDetail] = useState({})
	const [flow, setFlow] = useState([])
	const [affixList, setAffixList] = useState([])
	const [modalVisible, setModalVisible] = useState(false)
	const [pageId, setPageId] = useState('')
	const [auditRecord, setAuditRecord] = useState([])
	const [loading, setLoading] = useState(true)
	const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')

	useEffect(() => {
		let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
			areaTxt = ''
			result = ''
			if(location.search){
				setPageId(parseSearch(location.search).id)
				getOfficeWorkGetRow(parseSearch(location.search).id)
			}else{
				history.push({
					pathname: '/office/procedure'
				})
			}
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
	},[])
	//获取日常办公审批单条的详情
	const getOfficeWorkGetRow = (id) => {		
		api.getOfficeWorkGetRow({id: id}).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if(JSON.stringify(res) !== "[]"){			
				let data = Object.assign({}, res)
				if(data.status === 0)data.current = 0
				if(data.status === 1)data.current = 1
				if(data.status === 2)data.current = 2
				if(data.status === 3)data.current = 3
				data.auditList = data.audit.join('，')
				if(data.copyTo)data.copyToList = data.copyTo.join('，')
				if (data.content) {
					let nodes = data.content
					var reg = new RegExp('<table', 'g')
					, reg2 = new RegExp('<td', 'g')
					, reg3 = new RegExp('<th', 'g')
					nodes = nodes.replace(reg, '<table style="border:1px solid #666" ').replace(reg2, '<td style="border:1px solid #666" ').replace(reg3, '<th style="border:1px solid #666" ')
					data.content = nodes
				}
				setDetail(data)
				setFlow(data.flow)
				setAffixList(data.affix)
				setAuditRecord(data.auditRecord)
			}else{
				setDetail({})
				setFlow([])
				setAffixList([])
				setAuditRecord([])
			}
			setLoading(false)
		})
	}
	//驳回打开弹窗
	const onReject = () => {
		result = '不同意'
		setResPlaceholder('请输入至少1个字符')
		areaTxt = ''
		setModalVisible(true)	
	}
	//同意打开弹窗
	const onPass = () => {
		result = '同意'
		setResPlaceholder('请输入审批意见')
		areaTxt = ''
		setModalVisible(true)		
	}
	const oprateHandleOk = () => {
		if(areaTxt.length < 1 && result === '不同意'){
			message.warning('请输入至少1个字符')
			return
		}
		let params = {
			id: pageId,
			result: result,
			remark: areaTxt
		}
		api.onOfficeWorkAudit(params).then(res => {
			message.success('审批成功')
			setModalVisible(false)
			getOfficeWorkGetRow(pageId)
		})
	}
	//取消弹窗
	const oprateHandleCancel = () => {
		setModalVisible(false)	
	}
	//同意的样式
	const agreeNode = () => {
		return result === '同意' ? <>
			同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : <>
			不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
		</>
	}
	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}
	return (
		<Spin spinning={loading}>
			<div className="office-work-detail">
				<Descriptions title="审批详情" layout="horizontal" className="pro-detail-title">
					<dItem label="标题">{detail.title}</dItem>
				</Descriptions>
				<div className="step-wrap">
					{
						detail.current !== 3 && <Steps current={detail.current}>
						{
							flow.map((item) => {
								return <Step title={item.name} key={item.id} />
							})
						}
						</Steps>					
					}
					{
						detail.current === 3 && <Steps current={0} status="error">
							<Step title="已取消" />
						</Steps>
					}
				</div>
				<Descriptions title="申请内容"
					layout="horizontal"
					className="pro-detail-smailtitle"
				>
					<div className="content">
						{/* {detail.content} */}
						<div dangerouslySetInnerHTML={{
							__html: detail.content
						}}></div>
					</div>
				</Descriptions>
				<div className="info-box">				
					<div className="content">
					审批人：<span className="info">{detail.auditList}</span>
					</div>
					<div className="content">
					抄送人：<span className="info">{detail.copyToList && detail.copyToList.length ? detail.copyToList : '无'}</span>
					</div>
				</div>
				<Descriptions title="附件"
					layout="horizontal"
					className="pro-detail-smailtitle"
				>
					<div className="content">
						{
							affixList.length ? affixList.map((item, index) => {
								return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
							}) : '无'
						}
					</div>
				</Descriptions>
				<div className="record-list">
					<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
						<dItem label="审批人"></dItem>
						<dItem label="审批时间"></dItem>
						<dItem label="审批结果"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>
					{
						auditRecord.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
								<dItem label="">{item.userName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.result}</dItem>
								<dItem label="">{item.remark}</dItem>
							</Descriptions>
						})
					}
				</div>
				{
					detail.flag && <div className="btns">
						<Button onClick={onReject}>驳回</Button>
						<Button onClick={onPass} type="primary">通过</Button>
					</div>
				}
				<Modal
					title={agreeNode()}
					visible={modalVisible}
					onOk={oprateHandleOk}
					onCancel={oprateHandleCancel}
					destroyOnClose={true}
				>
					<div className="officework-modal-content">
						<span className="label">意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{width: "100%"}} maxLength={100} />
					</div>
				</Modal>
			</div>
		</Spin>
	)
}

export default OfficeWorkDetail
