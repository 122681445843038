/**
 * 模块名称: 链接跳转修改密码
 * @author zhuyan@372163.com
 */

import React, { Component } from 'react';
import './assets/style.scss'
import api from '@/api'
import { Icon, Button, Input, Form, message } from 'antd'
import { Link } from 'react-router-dom'

export class ShowPass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pasVal: '',
      repasVal: '',
      setPsd: false, // 控制设置
      finish: true, //控制完成
    }
  }

  inputpasValChange = (e) => {
    this.setState({ pasVal: e.target.value })
  }
  inputrepasValChange = (e) => {
    this.setState({ repasVal: e.target.value })
  }

  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }

  // 设置密码下一步
  setOwnStep = e => {
    let { pasVal, repasVal } = this.state
    e.preventDefault()
    let email = this.getQueryString("u")
    let sign = this.getQueryString("sign")
    let time = this.getQueryString("time")
    // console.log(email)
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const reg = /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])^[0-9A-Za-z\S]{7,20}$/
        if (!reg.test(values.password)) {
          message.error('登录密码不符合规则，请重新输入')
          return
        }
        if (values.password !== values.repassword) {
          message.error('两次密码输入不一致，请重新输入')
          return
        }
        api.setPassword({
          sign: sign,
          time: time,
          email: email,
          passWord: pasVal,
          rePassWord: repasVal
        }, true)
          .then(res => {
            this.setState({
              setPsd: true, // 控制设置
              finish: false, //控制完成
            })
          })
          .catch(err => {
            // this.props.nextStep()
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { pasVal, repasVal, finish, setPsd } = this.state
    return (
      <div className="own-body">
        <section className="forget-body-bg">
          {/* 底部 */}
          <section className="footer">
            <div className="footer-content">
              <p>版权所有：北京全时天地在线网络信息股份有限公司　京ICP备09035321号　京公网安备11010502030777号</p>
            </div>
          </section>
        </section>
        {/* 设置密码 */}
        <div className="own-main" hidden={setPsd}>
          <Form onSubmit={this.setOwnStep}>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{
                  required: true, message: '密码不能为空'
                },
                  //  { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{7,30}$/, message: '密码须同时含大、小写字母和数字，长度7~30位' }
                ],
              })(
                <span>
                  <Input.Password
                    className="own-Input-one"
                    size="large"
                    value={pasVal}
                    onChange={this.inputpasValChange}
                    prefix={<Icon type="shopping"
                      style={{ color: 'rgba(0,0,0,.25)', display: 'inline-block' }} />}
                    placeholder='登录密码'
                  />
                  <span className="show-setrule"> 注：7-20位字符，不能为纯数字，不能含有空格，必须同时包含大小写字母、数字。</span>
                </span>
              )}
            </Form.Item>
            <Form.Item>
              <span className="one_gap">
                {getFieldDecorator('repassword', {
                  rules: [{
                    required: true, message: '密码不能为空'
                  }],
                })(
                  <span>
                    <Input.Password
                      className="own-Input-one"
                      size="large"
                      value={repasVal}
                      onChange={this.inputrepasValChange}
                      prefix={<Icon type="shopping" style={{ color: 'rgba(0,0,0,.25)', display: 'inline-block' }} />}
                      placeholder='再次输入登录密码'
                    />
                  </span>
                )}
              </span>
            </Form.Item>
            <Form.Item>
              <span className="one-btn">
                <Button size="large" type="primary" htmlType="submit" >下一步</Button>
              </span>
            </Form.Item>
          </Form>
        </div>
        {/* 设置成功 */}
        <div className="own-main one-win" hidden={finish}>
          <div>
            <Icon type="check-circle" theme="filled" style={{ fontSize: '73px', color: '#52c41a' }} />
          </div>
          <div className="one-win-text">登录密码设置成功</div>
          <div className="one-win-again">请重新<Link to="/">登录</Link></div>
        </div>
      </div>
    );
  }
}
export default Form.create({ name: 'normal_login' })(ShowPass)
