/**
 * 模块名称: 到款详情页
 * @author wangchanghong@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Table,
  Spin,
  Popconfirm,
  message,
  Button
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import AuthMiddleware from "../../components/AuthMiddleware"
import { Link } from "react-router-dom"
import CryptoJS from 'crypto-js'

const DescriptionsItem = Descriptions.Item
const { Column } = Table
let pageSize = 10
let currentPage = 1

const RefundDetail = (props) => {
  const { history, location } = props

  const search = parseSearch(location.search)
  const [detailData, setDetailData] = useState({})
  const [invoiceList, setInvoiceList] = useState([])
  const [formLoading, setFormLoading] = useState(true)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      // console.log(search.new)
      setFormLoading(false)
      Promise.all([
        api.fundMoneyBackDetail({ id: search.id })
      ]).then(results => {

        setDetailData(results[0])

      }).finally(() => setFormLoading(false))
      onChangeTable({ current: currentPage, pageSize })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  const onChangeTable = (pagination) => {
    // currentPage = pagination.current
    // pageSize = pagination.pageSize

    api.fundMoneyBackGetClaimItemsOfMoneyBack({ id: search.id, isBindToMoneyBack: 1 }).then(data => {
      setInvoiceList(data)
    })
  }

  const onUnbind = (id) => {
    api.fundMoneyBackUnbindMoneyBack({
      id: id
    }).then(res => {
      message.success('解绑成功')
      onChangeTable({ current: currentPage, pageSize })
    })
  }

  //退回列表页
  const onGoBack = () => {
    history.push({ pathname: '/fund/moneyBack', state: { goBack: 1 } })
  }

  return (
    <Spin spinning={formLoading}>
      {
        search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
          position: 'absolute',
          right: '20px',
          top: 0
        }}>返回上一页</Button>
      }
      <div className="pro-detail">
        <Descriptions title={`到款信息（ID ${search.id}）`} layout="horizontal" column={4} style={{ marginBottom: '10px' }} >
          <DescriptionsItem label="到款银行">
            {detailData.bank}
          </DescriptionsItem>
          <DescriptionsItem label="到款日期">
            {detailData.date}
          </DescriptionsItem>
          <DescriptionsItem label="付款方用户名">
            {detailData.name}
          </DescriptionsItem>
          <DescriptionsItem label="付款方账户">
            {detailData.account}
          </DescriptionsItem>
          <DescriptionsItem label="到款金额">
            {detailData.amount}
          </DescriptionsItem>
          <DescriptionsItem label="余额">
            {detailData.balance}
          </DescriptionsItem>
          <DescriptionsItem label="备注">
            {detailData.note}
          </DescriptionsItem>
        </Descriptions>

        {invoiceList.length > 0 &&
          <>
            <div className="line-box"></div>

            <Descriptions title="认款信息" className="pro-detail-smailtitle" style={{ margin: '25px 0' }}></Descriptions>
            <Table
              dataSource={invoiceList}
              rowKey="id"
              scroll={{ x: 1000 }}
              pagination={false}
              style={{ marginBottom: '35px' }}
            >
              <Column title="到款方式" dataIndex="payWayName" />
              <Column title="客户类型" dataIndex="relationTypeName" />
              <Column title="账号/卡号/支票号/收据号" dataIndex="account" />
              <Column title="认款金额" dataIndex="rkQuota" />
              <Column title="实认金额" dataIndex="srQuota" />
              <Column title="认款状态" dataIndex="stCodeName" />
              <Column title="申请人" dataIndex="stfName" />
              <Column title="备注" dataIndex="remark" />
              <Column title="操作" render={(record) => {
                let del = null
                if (record.busCode === 3) {
                  del = (
                    <AuthMiddleware auths={detailData.operateAuth} code="operate">
                      <Popconfirm title="请确认是否解绑该认款明细，解绑后不可恢复。" cancelText="取消" okText="确认" onConfirm={(e) => onUnbind(record.id)}>
                        <a className="operate-icon" type="delete" >解绑</a>
                      </Popconfirm>
                    </AuthMiddleware>
                  )
                }
                return (
                  <>
                    <Link className="operate-icon" to={`/fund/moneyClaim/detail?id=${record.claimId}&clientId=${record.relationId}&clientType=${record.relationType}&time=${+new Date()}&sign=${CryptoJS.MD5(record.claimId + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>查看详情</Link>
                    {del}
                  </>
                )
              }} />
            </Table>
          </>
        }

        {JSON.stringify(detailData.refundList) != '[]' &&
          <>
            <div className="line-box"></div>

            <Descriptions title="退款信息" className="pro-detail-smailtitle" style={{ margin: '25px 0' }}></Descriptions>
            <Table
              dataSource={detailData.refundList}
              rowKey="id"
              scroll={{ x: 1000 }}
              pagination={false}
              style={{ marginBottom: '35px' }}
            >
              <Column title="客户名称" dataIndex="refundInfo.relationName" />
              <Column title="XOAID" dataIndex="refundInfo.relationId" />
              <Column title="收款单位" dataIndex="refundInfoExt.payeeCompany" />
              <Column title="开户行" dataIndex="refundInfoExt.bank" />
              <Column title="银行账号" dataIndex="refundInfoExt.bankAccountNo" />
              <Column title="退款金额" dataIndex="money" />
              <Column title="状态" dataIndex="refundStatusCn" />
              <Column title="申请人" dataIndex="refundInfo.applyStaffName" />
              <Column title="备注" dataIndex="refundInfo.remark" />
              <Column title="操作" key="oprate" render={(text, record) => {
                return <>
                  <Link target='_blank' to={`/fund/refundManage/details?id=${record.refundId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.refundId + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`} style={{ cursor: 'pointer' }}>查看详情</Link>
                </>
              }} />
            </Table>
          </>
        }

        <div className="line-box"></div>
        <Descriptions style={{ marginTop: 30, marginBottom: 0 }} title="">
          <DescriptionsItem label="当前状态">
            <span style={{ color: '#fff', backgroundColor: '#1890ff', padding: '2px 3px', borderRadius: '4px' }}>{detailData.typeName}</span>
          </DescriptionsItem>
        </Descriptions>

        {detailData.mnbStfId > 0 &&
          <>
            <Descriptions title="操作记录" className="pro-detail-smailtitle"></Descriptions>
            <Table
              dataSource={[detailData]}
              rowKey="id"
              scroll={{ x: 1000 }}
              pagination={false}
            >
              <Column title="操作人" dataIndex="mnbStfName" />
              <Column title="员工编号" dataIndex="mnbStfNo" />
              <Column title="操作时间" dataIndex="mnbAddTime" />
              <Column title="财务备注" dataIndex="mnbDetail" />
            </Table>
          </>
        }
      </div>
    </Spin>
  )
}

export default RefundDetail;