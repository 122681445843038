/**
 * 模块名称: 到款列表
 * @author wangchanghong@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Drawer,
  Row,
  Col,
  Modal,
  Popconfirm,
  DatePicker,
  Tooltip
} from 'antd'

import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import Import from '@/components/Import'
import Export from '@/components/Export'
import moment from 'moment'
import Edit from "./components/edit"
import Renkuan from "./components/renkuan"
import { Link } from "react-router-dom"
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let pageSize = 10
let currentPage = 1

let allFilter = {}

let filter = {
  branch: ''
}

let exporfilter = {

}

let selectedIds = ''
let params = {}

const dateFormat = 'YYYY-MM-DD'

const Refund = (props) => {
  const ImportRef = useRef()
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [expand, setExpand] = useState(false)
  const [ClaimTypeList, setClaimTypeList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [checked, setChecked] = useState(0)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [editData, setEditData] = useState({})
  const [isNew, setIsNew] = useState(false)
  const [visibleFile, setVisibleFile] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [moneyExport, setMoneyExport] = useState(false)

  useEffect(() => {
    // console.log(history.location.state)
    filter.branch = ''
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.pageSize ? +search.pageSize : 10
      filter.dateStart = ''
      filter.dateEnd = ''
      filter.account = ''
      filter.amount = ''
      filter.bank = ''
      filter.date = ''
      filter.moneyBackId = ''
      filter.mnbDetail = ''
      filter.type = ''
      filter.name = ''
      filter.accepted = ''
      filter.acceptedStart = ''
      filter.acceptedEnd = ''
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.pageSize ? +search.pageSize : pageSize
      setTimeout(() => {
        setFieldsValue({
          bank: filter.bank ? filter.bank : undefined,
          name: filter.name ? filter.name : undefined,
          account: filter.account ? filter.account : undefined,
          moneyBackId: filter.moneyBackId ? filter.moneyBackId : undefined,
          type: filter.type ? filter.type : undefined,
          mnbDetail: filter.mnbDetail ? filter.mnbDetail : undefined,
          amount: filter.amount ? filter.amount : undefined,
          date: filter.dateStart ? [moment(filter.dateStart, dateFormat), moment(filter.dateEnd, dateFormat)] : undefined,
          accepted: filter.acceptedStart ? [moment(filter.acceptedStart, dateFormat), moment(filter.acceptedEnd, dateFormat)] : undefined,
        })
      }, 1000)
    }
    getList()
    getClaimTypeList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.fundMoneyBackList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  const getClaimTypeList = () => {
    api.fundMoneyClaimGetTypes().then(data => {
      setClaimTypeList(data)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (vals.date) {
          delete vals.date
        }
        if (vals.accepted) {
          delete vals.accepted
        }
        filter = { ...filter, ...vals }
        // console.log(filter)
        history.replace(match.path)
        currentPage = 1
        getList()
      }
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    setChecked(0)
    setCheckedIdArr([])
    filter.branch = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    filter.dateStart = ''
    filter.dateEnd = ''
    filter.account = ''
    filter.amount = ''
    filter.bank = ''
    filter.branch = filter.branch
    filter.date = ''
    filter.moneyBackId = ''
    filter.mnbDetail = ''
    filter.type = ''
    filter.name = ''
    filter.accepted = ''
    filter.acceptedStart = ''
    filter.acceptedEnd = ''
    history.replace(match.path)
    currentPage = 1
    getList()
    setStatusVal(undefined)
    setStatusValName(undefined)
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[value].content
    // let data = JSON.parse(value)
    setFieldsValue({
      branch: data.branch ? data.branch : undefined,
      bank: data.bank ? data.bank : undefined,
      name: data.name ? data.name : undefined,
      account: data.account ? data.account : undefined,
      moneyBackId: data.id ? data.id : undefined,
      type: data.type ? data.type : undefined,
      mnbDetail: data.mnbDetail ? data.mnbDetail : undefined,
      amount: data.amount ? data.amount : undefined,
      date: data.dateStart ? [moment(data.dateStart, dateFormat), moment(data.dateEnd, dateFormat)] : undefined,
      accepted: data.acceptedStart ? [moment(data.acceptedStart, dateFormat), moment(data.acceptedEnd, dateFormat)] : undefined,
    })
    if (data.dateStart) {
      filter.dateStart = data.dateStart
      filter.dateEnd = data.dateEnd
    }
    if (data.acceptedStart) {
      filter.acceptedStart = data.acceptedStart
      filter.acceptedEnd = data.acceptedEnd
    }
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          if (vals.date && vals.date.length) {
            params.dateStart = moment(vals.date[0]).format(dateFormat)
            params.dateEnd = moment(vals.date[1]).format(dateFormat)
          }
          if (vals.accepted && vals.accepted.length) {
            params.acceptedStart = moment(vals.accepted[0]).format(dateFormat)
            params.acceptedEnd = moment(vals.accepted[1]).format(dateFormat)
          }

          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }

          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  // 添加
  const onAdd = () => {
    setEditData({})
    setIsNew(true)
    setEditVisible(true)
  }

  const onEdit = (data) => {
    setEditData(data)
    setIsNew(false)
    setEditVisible(true)
  }

  const onDelete = (id) => {
    api.fundMoneyBackDelete({
      id: id
    }).then(res => {
      message.success('删除成功')

      const index = checkedIdArr.indexOf(id);
      if (index > -1) {
        checkedIdArr.splice(index, 1);
        updateSelected(checkedIdArr)
      }
      getList()
    })
  }


  //导入
  const onImportFile = () => {
    setVisibleFile(true)
    // onRemoved就是子组件暴露给父组件的方法
    ImportRef.current.onRemoved()
  }

  const onCloseImport = () => {
    setVisibleFile(false)
  }

  const doImport = (fileList) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        api.fundMoneyBackImport({
          url: fileList[0].full
        }).then(data => {
          message.success('导入成功')
          ImportRef.current.onLoading()
          getList()
          setVisibleFile(false)
        }).catch(data => {
          ImportRef.current.onLoading()
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    if (filter.branch && filter.branch.length > 0) {
      if (!Array.isArray(filter.branch)) {
        filter.branch = filter.branch.split(',')
      }
    } else {
      filter.branch = []
    }
    Object.assign(allFilter, filter, exporfilter)
  }

  // 财务导出
  const onMoneyExportFile = () => {
    setMoneyExport(true)
    Object.assign(allFilter, filter, exporfilter)
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.dateStart = ''
      filter.dateEnd = ''
    } else {
      filter.dateStart = moment(data[0]).format(dateFormat)
      filter.dateEnd = moment(data[1]).format(dateFormat)
    }
  }

  const onChangePickerAccepted = (acceptedVal) => {
    if (acceptedVal.length === 0) {
      filter.acceptedStart = ''
      filter.acceptedEnd = ''
    } else {
      filter.acceptedStart = moment(acceptedVal[0]).format(dateFormat)
      filter.acceptedEnd = moment(acceptedVal[1]).format(dateFormat)
    }
  }

  const rowSelection = {
    onSelect: (record, selected) => {
      if (!selected) {
        checkedIdArr.splice(checkedIdArr.indexOf(record.id), 1);
      } else {
        checkedIdArr.push(record.id)
      }
      updateSelected(checkedIdArr)
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log(selected, selectedRows, changeRows)
      if (selected) {
        changeRows.map(item => checkedIdArr.push(item.id))
      } else {
        changeRows.map(item => checkedIdArr.splice(checkedIdArr.indexOf(item.id), 1))
      }
      updateSelected(checkedIdArr)
    },
    selectedRowKeys: checkedIdArr
  }

  const updateSelected = (checkedIdArr) => {
    setCheckedIdArr(checkedIdArr)
    setChecked(checkedIdArr.length)
    selectedIds = checkedIdArr
  }

  const onRenkuan = (id) => {
    setRenKuanVisible(true)
    setRenKuanId(id)
  }

  const [renKuanVisible, setRenKuanVisible] = useState(false)
  const [renKuanId, setRenKuanId] = useState(0)

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="到款银行" labelCol={{ span: 8 }}>
                  {getFieldDecorator('bank', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="付款方户名" labelCol={{ span: 8 }}>
                  {getFieldDecorator('name', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="付款方账号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('account', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="到款日期" labelCol={{ span: 8 }}>
                  {getFieldDecorator('date', { initialValue: '' })(<RangePicker onChange={onChangePicker} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="财务通过时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('accepted', { initialValue: '' })(<RangePicker onChange={onChangePickerAccepted} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="到款金额" labelCol={{ span: 8 }}>
                  {getFieldDecorator('amount', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="到款ID" labelCol={{ span: 8 }}>
                  {getFieldDecorator('moneyBackId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="认款状态" labelCol={{ span: 8 }}>
                  {getFieldDecorator('type')(
                    <Select placeholder="请选择">
                      {ClaimTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="财务认款说明" labelCol={{ span: 8 }}>
                  {getFieldDecorator('mnbDetail')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{ paddingBottom: 10 }}>
          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                style={{ width: 160 }}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
              >
                {filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                )}
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm} style={{ width: 130, margin: '0 15px' }}>保存筛选条件</Button>
          </div>
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="operate-wrap">
        <Auth auths={authList} code="operate">
          <Button type="primary" icon="plus" onClick={onAdd}>添加</Button>
          <Button type="primary" icon="download" onClick={onImportFile} style={{ marginLeft: 15 }}>导入</Button>
          <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
        <Auth auths={authList} code="finance-export">
          <Button type="primary" icon="upload" onClick={onMoneyExportFile} style={{ marginLeft: 15 }}>财务导出</Button>
        </Auth>
        <Import
          onSearch={doImport}
          visibleFile={visibleFile}
          onCloseImport={onCloseImport}
          ImportTemplate="/api/File/DownTemplate?url=/templates/fundMoneyBack.xlsx&name=到款管理导入模板"
          ImportRef={ImportRef}
        />
      </div>
      <Alert className="count-alert" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1600 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="到款ID" dataIndex="id" width={120} />
        <Column title="到款银行" dataIndex="bank" />
        <Column title="到款日期" dataIndex="date" />
        <Column title="付款方户名" dataIndex="name" />
        <Column title="付款方账号" dataIndex="account" />
        <Column title="到款金额" dataIndex="amount" />
        <Column title="余额" dataIndex="balance" />
        <Column title="认款次数" dataIndex="claimCount" />
        <Column title="退款次数" dataIndex="refundNum" />
        <Column title="财务通过时间" dataIndex="acceptedCwAt" />
        <Column title="识别码" dataIndex="uniqueCode" />
        <Column title="备注" dataIndex="note" />
        <Column title="认款状态" dataIndex="typeName" />
        <Column title="操作"
          key="set"
          fixed="right"
          width={163}
          render={(record) => {
            let edit = null
            let renkuan = null
            let detail = null
            let detailNew = null
            if (record.type === 1 && record.operateAuth && !record.refundNum > 0 && record.type !== 5) {
              edit = (
                <>
                  <Auth auths={record.operateAuth} code="operate">
                    <a className="operate-icon" type="edit" onClick={(e) => onEdit(record)}>编辑</a>
                  </Auth>
                  {' '}
                  <Auth auths={record.operateAuth} code="operate">
                    <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={(e) => onDelete(record.id)}>
                      <a className="operate-icon" type="delete" >删除</a>
                    </Popconfirm>
                  </Auth>
                </>
              )
            }
            if (record.claimCount === 0 && record.mnbkType === 0 && record.operateAuth && record.type !== 5) {
              renkuan = (
                <>
                  <Auth auths={record.operateAuth} code="operate">
                    <a className="operate-icon" type="edit" onClick={() => onRenkuan(record.id)}>财务认款</a>
                  </Auth>
                </>
              )
            }
            detail = (
              <>
                <Link className="operate-icon" to={`${match.path}/detail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>查看详情</Link>
              </>
            )
            detailNew = (
              <>
                <Tooltip title="在新页面打开详情页" placement="bottom">
                  <Link target='_blank' className="operate-icon" to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>新页面</Link>
                </Tooltip>
              </>
            )
            return (
              <>
                {edit}
                {renkuan}
                {detail}
                {detailNew}
              </>
            )
          }
          }
        />
      </Table>
      <Drawer
        title="到款管理添加/编辑"
        width={600}
        onClose={() => setEditVisible(false)}
        visible={editVisible}
      >
        <Edit data={editData} setVisible={setEditVisible} dateFormat={dateFormat} getList={getList} isNew={isNew} />
      </Drawer>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getFundMoneyBackExportTpl"
        fieldsUrl="getFundMoneyBackTplItems"
        saveUrl="saveFundMoneyBackExportTpls"
        exportUrl="fundMoneyBackExport"
        method="export"
        parame={{ ExportType: 'fundmoneyback.export', id: selectedIds, ...allFilter }}
        type={1}
        cRef={productsRef}
      />
      {/* 财务导出 */}
      <Export
        show={moneyExport}
        onCancel={() => setMoneyExport(false)}
        tplUrl="getFundMoneyBackExportTpl"
        fieldsUrl="getFundMoneyBackTplItems"
        saveUrl="saveFundMoneyBackExportTpls"
        exportUrl="fundMoneyBackExport"
        method="export"
        ExportType="fundmoneyback_caiwu.export"
        parame={{ ExportType: 'fundmoneyback_caiwu.export', id: selectedIds, ...allFilter }}
      />
      <Renkuan getList={getList} renKuanVisible={renKuanVisible} setRenKuanVisible={setRenKuanVisible} id={renKuanId} />
    </>
  )
}

export default Form.create()(Refund)