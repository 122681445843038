/**
 * 模块名称: 文档列表详情
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import { parseSearch } from '@/utils'
import { Tabs, Button, message } from 'antd'
// import Upload from '@/components/Upload'
import './style.scss'
import urls from '@/api/urls'
import CryptoJS from 'crypto-js'

const { TabPane } = Tabs
// const urls = []

const Details = (props) => {
  //console.log(props)
  const { history } = props
  const [data, setData] = useState({
    title: '',
    list: []
  })
  const search = parseSearch(props.location.search)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo =  CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getIndexDocDetails({ id: search.id }, true).then(data => {
        //api.setMessageReaded({ ids: [search.msgId] })
        setData({
          title: data.title,
          list: data.affix,
          id: data.id
        })
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  function callback(key) {
    // console.log(key)
  }

  function affixHandle(data) {
    let url = data.url
    // let origin = window.location.origin
    // if (origin.indexOf('localhost' !== -1)) {
    //   url = 'http://devnewoa.372163.com' + data.url
    // }
    // else {
    //   url = origin + data.url
    // }

    if (data.type !== 'pdf') {
      url = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
    }

    return <embed height="680" width="100%" src={url} />
  }

  /*function onChangeUpload(url) {
    //console.log(url)
    urls.push(url)
    //console.log(urls)
  }

  function save() {
    api.saveDoc({
      title: '测试-多条附件',
      type: '2',
      affix: urls.join(','),
      scope: [1,2],
      id: ''
    })
  }*/

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={callback} animated={false}>
        {data.list.map((item, index) =>(
          <TabPane tab={`附件${index+1}`} key={index}>
            {affixHandle(item)}
            <div className="download-btn-wrap">
              <Button onClick={history.goBack}>关闭</Button>
              <a href={urls.downloadIndexdoc.url + '?id=' + data.id} target="_blank" className="ant-btn ant-btn-primary" rel="noopener noreferrer">下载附件</a>
            </div>
          </TabPane>
        ))}
      </Tabs>
      {/* <Upload onChange={onChangeUpload} /> */}
      {/* <Button onClick={save}>保存</Button> */}
    </>
  )
}

export default Details