/**
 * 模块名称: 文档管理
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import api from 'api'
import {
  Table,
  Form,
  Drawer,
  DatePicker,
  Input,
  Button,
  Select,
  Alert,
  Icon,
  Popconfirm,
  Switch,
  message,
  Spin,
  Tooltip
} from 'antd'
import UploadDoc from './module/UploadDoc'
import BtnGroup from 'components/BtnGroup'
import Auth from 'components/AuthMiddleware'
import { parseSearch } from 'utils'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}
//分类管理分页
let sortPageSize = 10
let sortCurrentPage = 1

let intervalRef = {}

const Document = (props) => {

  const { getFieldDecorator, validateFields, resetFields } = props.form
  const { history, match } = props

  const [docTypeList, setDocTypeList] = useState([])
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [typeVisible, setTypeVisible] = useState(false)
  const [addTypeName, setAddTypeName] = useState('')
  
  const [sortVisible, setSortVisible] = useState(false)
  const [sortList, setSortList] = useState([])
  const [sortLoading, setSortLoading] = useState(true)
  const [sortCount, setSortCount] = useState(0)

  const [sortIdTake, setSortIdTake] = useState('')
  const [addTypeDrawerName, setAddTypeDrawerName] = useState('添加分类')

  const [uploadDrawerName, setUploadDrawerName] = useState('上传文档')
  const [editRowId, setEditRowId] = useState('')
  const [uploadLoading, setUploadLoading] = useState(true)

  const [authList, setAuthList] = useState([])//按钮权限
  const search = parseSearch(props.location.search)


  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    sortPageSize = 10
    sortCurrentPage = 1
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getDocManageDocList()
    getPageAuth()
    return () => {
      for(let i in intervalRef){//eslint-disable-line
        clearTimeout(intervalRef[i])
      }
    }
  }, [])
  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }
  //获取文档类型列表
  const getDocTypeList =  () => {
    api.getDocTypePageTypeData().then(res => {
      setDocTypeList(res.list)
    })
  }
  //选择文档类型
  const getDocType = () => {

  }
  //获取文档管理列表
  const getDocManageDocList = () => {
    setLoading(true)
    intervalRef.current = setTimeout(() => {
      api.getDocManageDocList(searchParamsTmp).then(res => {
        setList(res.list)
        setCount(res.count)
        setLoading(false)
        clearTimeout(intervalRef.current)
      }).catch(() => setLoading(false))
    }, 500)
  }
  //改变状态
  const onChangeStatus = (id, val) => {
    api.onDocManageStick({id: Number(id), status: val ? 0 : 1}).then(() => {
      getDocManageDocList()
    })
  }
  //编辑 
  const onEdit = (record) => {
    //console.log(record)
    setEditRowId(record.id)
    setUploadDrawerName('修改文档')
    setVisible(true)
  }
  //删除
  const onDelete = (id) => {
    api.onDocManageDel({id}, true).then(() => {
      message.success('删除成功')
      //resetFields()
      getDocManageDocList()
    })
  }
  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      if(vals !== undefined){
        if(vals.docTitle !== undefined)searchParamsTmp.title = vals.docTitle
        if(vals.docCreator !== undefined)searchParamsTmp.name = vals.docCreator
        if(vals.docType !== undefined)searchParamsTmp.type = vals.docType
        if(vals.inpicker !== undefined){
          searchParamsTmp.sTime = vals.inpicker ? vals.inpicker[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.eTime = vals.inpicker ? vals.inpicker[1].format('YYYY-MM-DD') : ''
        }
      }
      if(!err){
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getDocManageDocList()
      }
    })
  }
  //重置表单
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getDocManageDocList()
  }
  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getDocManageDocList()
  }
  //打开上传文档
  const onOpenUploadDoc = () => {
    setAddTypeName('')
    setAddTypeDrawerName('添加分类')
    setUploadDrawerName('上传文档')
    setSortIdTake('')
    setEditRowId('')
    setVisible(true)
  }
  //打开分类管理
  const onOpenSortMana = () => {
    setSortVisible(true)
    getDocTypeTypeList()
  }
  //分类名字change
  const changeTypeName = (e) => {
    const reg = /(^\s*)|(\s*$)/g
    let name = e.target.value.replace(reg, '')
    setAddTypeName(name)
  }
  //添加文档类型分类保存
  const saveAddType = () => {
    if(!addTypeName.length){
      message.warning('请填入正确的分类信息')
      return
    }
    let params = {
      name: addTypeName
    }
    if(sortIdTake)params.id = sortIdTake
    api.onDocTypeTypeHandle(params).then(res => {
      //console.log(res)
      message.success('操作成功')
      setAddTypeName('')
      setTypeVisible(false)
      getDocTypeTypeList()
    })
  }
  //分类管理点击分页
  const onChangeSortTable = (pagination, filters, sorter) => {
    sortCurrentPage = pagination.current
    sortPageSize = pagination.pageSize
    getDocTypeTypeList()
  }
  //分类管理获得列表
  const getDocTypeTypeList = () => {
    let params = {
      page: sortCurrentPage,
      limit: sortPageSize
    }
    api.getDocTypeTypeList(params).then(res => {
      setSortList(res.list)
      setSortCount(res.count)
      setSortLoading(false)
    }).catch(() => { setSortLoading(false) })
  }
  //编辑分类列表item
  const onEditSort = (record) => {
    //console.log(record)
    setAddTypeName(record.name)
    setAddTypeDrawerName('修改分类')
    setSortIdTake(record.id)
    setTypeVisible(true)
    //setSortVisible(false)
  }
  //分类管理中 添加分类按钮
  const addType =  () => {
    setAddTypeName('')
    setAddTypeDrawerName('添加分类')
    setSortIdTake('')
    setTypeVisible(true)
    //setSortVisible(false)
  }
  return (
    <>
      <div className="search-doc-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <div className="form-box">
            <FormItem label="标题">
              {getFieldDecorator('docTitle', {
              })(<Input autoComplete="off" placeholder="请输入" />)}
            </FormItem>
            <FormItem label="文档类型">
              {getFieldDecorator('docType')(<Select
                placeholder="请选择" 
                onDropdownVisibleChange={bool => bool && getDocTypeList()}
                onChange={getDocType}
              >
                {
                  docTypeList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="创建人">
              {getFieldDecorator('docCreator', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="创建时间">
              {getFieldDecorator('inpicker', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div>
        <div className="add-wrap">
          <Auth auths={authList} code="operate">
            <Button type="primary" icon="plus" onClick={onOpenSortMana} title="分类管理">分类管理</Button>
            <Button type="primary" icon="plus" onClick={onOpenUploadDoc} style={{marginLeft: '15px'}} title="上传文档">上传文档</Button>
          </Auth>
        </div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"  
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          // scroll={{x: 1400}}
          onChange={onChangeTable}
        >
          <Column title="标题" key="title" render={(text, record) =>
            <>
              {
                <Link to={`${match.path}/details?id=${text.id}&time=${+new Date()}&sign=${CryptoJS.MD5(text.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.title}</Link>
              }
            </>
          } />  
          <Column title="文件类型" dataIndex="typeName" />
          <Column title="创建人" dataIndex="userName" />     
          <Column title="是否置顶" dataIndex="stick" render={(text, record) =>  <Switch size="small" defaultChecked={record.stick} onChange={val => onChangeStatus(record.id, val)} />} />
          <Column title="创建时间" dataIndex="addTime" />
          <Column title="操作"
            key="set" 
            fixed="right"
            width={100}
            render={(text, record) =>
              <>
                <Auth auths={text.operateAuth} code="operate">
                  <Tooltip title="编辑" placement="bottom">
                    <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
                  </Tooltip>
                  {' '}
                  <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                    <Tooltip title="删除" placement="bottom">
                      <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                    </Tooltip>
                  </Popconfirm>
                </Auth>
              </>
            }
          />
        </Table>
      </div>
      <Drawer
        title={uploadDrawerName}
        width={800}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
        zIndex="1000"
      >
        <Spin spinning={uploadLoading}>
          <UploadDoc
            setVisible={setVisible}
            getDocManageDocList={getDocManageDocList}
            setTypeVisible={setTypeVisible}
            editRowId={editRowId}
            setUploadLoading={setUploadLoading}
          />
        </Spin>
      </Drawer>
      <Drawer
        title={
          <div style={{position: "relative"}}>
            分类管理
            <Icon onClick={addType} type="plus-circle" style={{position: "absolute", right: "40px", top: "5px", cursor: "pointer"}} title="添加分类" />
          </div>
        }
        width={800}
        onClose={() => setSortVisible(false)}
        visible={sortVisible}
        destroyOnClose={true}
        className="manage-sort-drawer"
        zIndex="1000"
      >
        <Table
          dataSource={sortList}
          rowKey="id"  
          className="sort-table"
          loading={sortLoading}
          pagination={{
            pageSize: sortPageSize,
            total: sortCount,
            current: sortCurrentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeSortTable}
        >
          <Column title="名称" dataIndex="name" />
          <Column title="创建人" dataIndex="userName" />
          <Column title="创建时间" dataIndex="addTime" />
          <Column title="操作"
            key="set" 
            render={(text, record) =>
              <>
                <Icon className="operate-icon" type="edit" onClick={() => onEditSort(record)} />
              </>
            }
          />
        </Table>
      </Drawer>
      <Drawer
        title={addTypeDrawerName}
        width={600}
        onClose={() => {
          setTypeVisible(false)
          setAddTypeName('')
        }}
        visible={typeVisible}
        destroyOnClose={true}
        className="add-type-drawer"
        zIndex="1001"
      >
        <div className="add-type">
          <label htmlFor="">分类名称</label>
          <input placeholder="请输入分类名称" className="ant-input" value={addTypeName} type="text" onChange={changeTypeName} />
        </div>
        <BtnGroup onCancel={() => setTypeVisible(false)} onConfirm={saveAddType} />
      </Drawer>
    </>
  );
}

export default Form.create()(Document)
