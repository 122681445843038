/**
 * 模块名称: 手机找回密码
 * @author zhuyan@372163.com
 */
import React, { Component } from 'react'
// import './assets/style.scss'
import api from '@/api'
import { Icon, Button, Input, Form, message } from 'antd'

class mobileForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      codeVal: '', //图形值
      mobilelVal: '', //手机号值
      mobileCodeVal: '',//手机验证码
      timeValue: "获取验证码",
      codeDisabled: true, //获取验证码btn
      mobileFlag: false, //帐号值是否正确
      codeFlag: false, //验证码是否正确
      timeoutFlag: false, //是否在倒计时

    }
  }
  inputMobileChange = (e) => {
    this.setState({ mobilelVal: e.target.value })
  }

  //手机号码是否存在
  blurMobileInput = () => {
    let mobile = this.state.mobilelVal
    if (mobile.trim() !== '') {
      api.forgetCheckPhone({
        mobile: this.state.mobilelVal
      }, true)
        .then(res => {
          this.setState({ mobileFlag: true })
          if (this.state.mobileFlag && this.state.codeFlag && !this.state.timeoutFlag) {
            this.setState({ codeDisabled: false })
          }
        })
        .catch(err => {
          this.setState({
            mobileFlag: false,
            codeDisabled: true
          })
        })
    }
  }

  inputCodeValChange = (e) => {
    this.setState({ codeVal: e.target.value })
  }

  //验证图形验证码
  inputCodeValBlur = (e) => {
    let { codeVal } = this.state
    if (codeVal.trim() !== '') {
      api.checkImageCode({
        imageKey: this.props.ImgKey,
        code: codeVal
      }, true)
        .then(res => {
          this.setState({ codeFlag: true })
          if (this.state.mobileFlag && this.state.codeFlag && !this.state.timeoutFlag) {
            this.setState({ codeDisabled: false })
          }
        })
        .catch(err => {
          this.props.getCaptcha()
          this.setState({
            codeVal: '',
            codeFlag: false,
            codeDisabled: true
          })
        })
    }
  }

  // 获取手机验证码 
  getPhoneCode = () => {
    let { mobilelVal } = this.state
    api.getPhoneCode({
      phone: mobilelVal,
      type: 2
    }, true)
      .then(res => {
        this.timeoutInterval()
      })
      .catch(err => { })
  }

  inputMobileCodeVal = (e) => {
    this.setState({ mobileCodeVal: e.target.value })
  }


  // 倒计时
  timeoutInterval() {
    this.setState({ codeDisabled: true })
    let sumTime = 60
    let interval = setInterval(() => {
      if (sumTime > 0) {
        sumTime--
        this.setState({
          timeoutFlag: true,
          timeValue: "获取验证码(" + sumTime + "S)"
        })
      } else {
        clearInterval(interval)
        this.setState({
          timeoutFlag: false,
          codeDisabled: false,
          timeValue: "获取验证码"
        })
      }
    }, 1000)
  }

  //手机号下一步
  formSubmit = (e) => {
    e.preventDefault()
    let { mobilelVal, mobileCodeVal } = this.state, type = 1
    const { validateFields } = this.props.form
    validateFields((err, values) => {
      if (!err) {
        if (mobileCodeVal.trim() === '') {
          message.error('验证码不能为空')
          return
        }
        api.checkCode({
          account: mobilelVal,
          type: 2,
          code: mobileCodeVal
        }, true)
          .then(res => {
            this.props.nextStep()
            this.props.mobilelValFn(mobilelVal, type)
          })
          .catch(err => {
            this.props.getCaptcha()
            this.setState({
              codeVal: '',
              mobileCodeVal: '',
              codeFlag: false,
              codeDisabled: true
            })
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { mobilelVal, codeVal, mobileCodeVal, codeDisabled, timeValue } = this.state
    return (
      <Form onSubmit={this.formSubmit}>
        <Form.Item>
          {getFieldDecorator('mobile', {
            rules: [{
              required: true, message: '手机号码不能为空'
            },
              //  {
              //   pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '手机号码格式不正确'
              // }
            ],
          })(
            <span className="one_gap">
              <Input
                size="large"
                prefix={<Icon type="mobile" value={mobilelVal} style={{ color: 'rgba(0,0,0,.25)', display: 'inline-block' }} />}
                placeholder='11位手机号'
                onChange={this.inputMobileChange}
                onBlur={this.blurMobileInput}
              />
            </span>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('securityCode', {
            rules: [{ required: true, whitespace: true, message: '图形验证码不能为空' }],
          })(
            <span className="one-yx one_gap">
              <Input
                size="large"
                className="one_Input"
                prefix={<Icon type="safety-certificate" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='验证码'
                value={codeVal}
                onChange={this.inputCodeValChange}
                onBlur={this.inputCodeValBlur}
              />
              <span style={{cursor: 'pointer'}}><img className="one-yx-code" src={this.props.Imgsrc} alt="" onClick={this.props.replaceCode} /></span>
            </span>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('send', {
            rules: [{ required: true, message: '手机验证码不能为空' }],
          })(
            <span className="one-yx one_gap">
              <Input
                size="large"
                className="one_Input"
                prefix={<Icon type="message" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='短信验证码'
                value={mobileCodeVal}
                onChange={this.inputMobileCodeVal}
              />
              <Button size="large" type="primary" onClick={this.getPhoneCode} disabled={codeDisabled} >{timeValue}</Button>
            </span>
          )}
        </Form.Item>
        <Form.Item>
          <span className="one-btn one_gap">
            <Button type="primary" size="large" htmlType="submit" >下一步</Button>
          </span>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create({ name: 'normal_login' })(mobileForm)