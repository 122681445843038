/**
 * 模块名称: 日常办公 => 费用管理列表
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
	Table,
	Form,
	Input,
	Button,
	Select,
	message,
	DatePicker,
	Row,
	Col,
	Popconfirm,
	Tooltip,
	Switch
} from 'antd'
import CorpFilter from '@/components/CorpQuanQian'
import BtnGroup from '@/components/BtnGroup'
import Export from '@/components/Export'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let companyId = []
let searchParamsTmp = {
	limit: pageSize,
	page: currentPage,
	companyId: companyId,
	shouldMe: ''
}
let filter = {}
let selectedIds = ''

const CostManagement = props => {
	const { history, match, location } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
	const productsRef = useRef()
	const [authList, setAuthList] = useState([])
	const [count, setCount] = useState(0)
	const [list, setList] = useState([])
	const [loading, setLoading] = useState(false)
	const [visible, setVisible] = useState(false)
	const [costClassList, setCostClassList] = useState([])//获取费用类别
	const [payWayList, setPayWayList] = useState([])//获取付款方式
	const [busStatusList, setBusStatusList] = useState([])//获取业务状态
	const [isShow, setIsShow] = useState(false)//待审批
	const [showExport, setShowExport] = useState(false)//导出
	const search = parseSearch(props.location.search)

	useEffect(() => {
		// console.log(history.location.state)
		if (!history.location.state) {
			currentPage = search.page ? +search.page : 1
			pageSize = search.limit ? +search.limit : 10
			searchParamsTmp = {
				limit: pageSize,
				page: currentPage,
				shouldMe: ''
			}
		} else {
			currentPage = search.page ? +search.page : currentPage
			pageSize = search.limit ? +search.limit : pageSize
			initGoback()
		}
		companyId = []
		common()
		getOfficeCostManagementList()
		api.getPageAuth().then(list => setAuthList(list))
	}, [])

	//公共接口
	const common = () => {
		api.getOfficeCostManagementCostClass().then(res => setCostClassList(res)).catch(err => console.log(err))//获取费用类别
		api.getOfficeCostManagementPayWay().then(res => setPayWayList(res)).catch(err => console.log(err))//获取付款方式
		api.getOfficeCostManagementBusStatus().then(res => setBusStatusList(res)).catch(err => console.log(err))//获取业务状态
	}

	const initGoback = () => {
		// console.log(searchParamsTmp)
		let timer = setTimeout(() => {
			initFieldsVal(searchParamsTmp)
			setFieldsValue({
				addTime: searchParamsTmp.sTime ? [moment(searchParamsTmp.sTime), moment(searchParamsTmp.eTime)] : undefined
			})
			clearTimeout(timer)
		}, 1000)
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}

	//点击选择所属公司
	const onChangeCorp = (data) => {
		currentPage = 1
		history.replace(match.path)
		if (data.length) {
			let arr = []
			data.forEach((item, index) => {
				arr.push(item.id)
			})
			companyId = [].concat(arr)
			searchParamsTmp = {
				limit: pageSize,
				page: currentPage,
				companyId: companyId,
				shouldMe: ''
			}
		} else {
			companyId = []
			searchParamsTmp = {
				limit: pageSize,
				page: currentPage,
				shouldMe: ''
			}
		}
		getOfficeCostManagementList()
	}

	//点击重置
	const onReset = () => {
		currentPage = 1
		history.replace(match.path)
		searchParamsTmp = {
			limit: pageSize,
			page: currentPage,
			companyId: companyId,
			shouldMe: ''
		}
		resetFields()
		setIsShow(false)
		getOfficeCostManagementList()
	}

	//分页点击
	const onChangeTable = (pagination, filters, sorter) => {
		searchParamsTmp.page = currentPage = pagination.current
		searchParamsTmp.limit = pageSize = pagination.pageSize
		history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
		getOfficeCostManagementList()
	}

	//获取列表
	const getOfficeCostManagementList = () => {
		setLoading(true)
		if (companyId.length) searchParamsTmp.companyId = companyId
		api.getOfficeCostManagementList(searchParamsTmp).then(res => {
			setCount(res.count)
			setList(res.list)
			setLoading(false)
		}).catch(() => {
			setLoading(false)
		})
	}

	//数据转换
	const transData = (vals) => {
		if (vals !== undefined) {
			for (let i in vals) {
				if (vals[i] !== undefined && i == 'addTime') {
					searchParamsTmp.sTime = moment(vals[i][0]).format('YYYY-MM-DD')
					searchParamsTmp.eTime = moment(vals[i][1]).format('YYYY-MM-DD')
				}
				// else if (i == 'userName') {
				// 	if (typeof parseInt(vals[i]) === 'number' && !isNaN(vals[i])) {
				// 		//纯数字
				// 		searchParamsTmp.userNumber = vals[i]
				// 	} else {
				// 		//非纯数字
				// 		searchParamsTmp.userName = vals[i]
				// 	}
				// } 
				else {
					searchParamsTmp[i] = vals[i]
				}
			}
			// console.log('searchParamsTmp=>', searchParamsTmp)
		}
	}

	//点击筛选
	const searchSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			//console.log(vals)
			transData(vals)
			if (!err) {
				searchParamsTmp.page = currentPage = 1
				history.replace(match.path)
				getOfficeCostManagementList()
			}
		})

	}

	// 填充表单值
	const initFieldsVal = data => {
		setFieldsValue({
			id: data.id ? data.id : undefined,
			costClass: data.costClass ? data.costClass : undefined,
			payWay: data.payWay ? data.payWay : undefined,
			applyStaffName: data.applyStaffName ? data.applyStaffName : undefined,
			busCode: data.busCode ? data.busCode : undefined,
			payee: data.payee ? data.payee : undefined,
			bankName: data.bankName ? data.bankName : undefined,
			bankAccount: data.bankAccount ? data.bankAccount : undefined,
			money: data.money ? data.money : undefined,
		})
	}

	//操作=> 删除
	const onDelete = (record) => {
		api.setOfficeCostManagementDel({ id: record.id }).then(res => {
			message.success('删除成功')
			getOfficeCostManagementList()
		})
	}

	//待审批
	const onSetShouldMe = (checked) => {
		currentPage = 1
		setIsShow(checked)
		if (checked) {
			searchParamsTmp.shouldMe = 1
		} else {
			searchParamsTmp.shouldMe = ''
		}
		setTimeout(() => {
			getOfficeCostManagementList()
		}, 600)
	}

	//导出
	const onExportFile = () => {
		setShowExport(true)
		filter = searchParamsTmp
		delete filter.limit
		delete filter.page
		delete filter.shouldMe
		if (searchParamsTmp.companyId && searchParamsTmp.companyId.length > 0) {
			if (!Array.isArray(searchParamsTmp.companyId)) {
				searchParamsTmp.companyId = searchParamsTmp.companyId.split(',')
			}
		} else {
			searchParamsTmp.companyId = []
		}
		// Object.assign(filter)
	}

	return (
		<>
			<CorpFilter onChange={onChangeCorp} part='feiyong' />
			<div className="comm-stay-lists">
				<Form onSubmit={searchSubmit} {...formItemLayout}>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="业务编号">
								{getFieldDecorator('id')(<Input
									allowClear={true}
								// placeholder="请输入业务编号"
								/>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="费用类别">
								{getFieldDecorator('costClass')(<Select
									placeholder="全部"
									allowClear={true}
									showSearch={true}
									optionFilterProp="children"
								>
									{
										costClassList.map(v => {
											return <Option key={v.id}>{v.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label="费用类别具体名称">
								{getFieldDecorator('costClassName')(<Input allowClear={true} />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="付款方式">
								{getFieldDecorator('payWay')(<Select
									placeholder="全部"
									allowClear={true}
								>
									{
										payWayList.map((item, index) => {
											return <Option key={item.id}>{item.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="申请人">
								{getFieldDecorator('applyStaffName')(<Input
									allowClear={true}
								// placeholder="请输入申请人"
								/>)}
							</FormItem>
						</Col>
						<Col span={5}>
							<FormItem label="状态">
								{getFieldDecorator('busCode')(<Select
									placeholder="全部"
									allowClear={true}
								>
									{
										busStatusList.map(item => {
											return <Option key={item.id}>{item.name}</Option>
										})
									}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={7}>
							<FormItem label="收款单位">
								{getFieldDecorator('payee')(<Input allowClear={true} />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
					  <Col span={6}>
							<FormItem label="开户行">
								{getFieldDecorator('bankName')(<Input allowClear={true} />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="银行账号">
								{getFieldDecorator('bankAccount')(<Input allowClear={true} />)}
							</FormItem>
						</Col>
						<Col span={5}>
							<FormItem label="付款金额">
								{getFieldDecorator('money')(<Input allowClear={true} />)}
							</FormItem>
						</Col>
						<Col span={7}>
							<FormItem label="申请时间">
								{getFieldDecorator('addTime', {
								})(<RangePicker allowClear={false} />)}
							</FormItem>
						</Col>
					</Row>
					<div className="comm-stay-btns" >
						<Switch
							checkedChildren="待审批"
							unCheckedChildren="待审批"
							checked={isShow}
							onClick={onSetShouldMe}
							style={{ marginRight: '24px' }}
							className="shouldme-switch-btn"
						/>
						<BtnGroup cancelName="重置" confirmName="查询" onCancel={onReset} />
					</div>
				</Form>
			</div>
			<div className="line"></div>
			<div className="add-wrap">
				<Auth auths={authList} code="cost-apply">
					<Link to={`${match.path}/apply`} style={{ cursor: 'pointer' }}>
						<Button type="primary">费用申请</Button>
					</Link>
				</Auth>
				<Auth auths={authList} code="export">
					<Button type="primary" style={{ marginLeft: '20px' }} onClick={onExportFile}>导出</Button>
					<Export
						show={showExport}
						onCancel={() => {
							setShowExport(false)
							productsRef.current.changeVal()
						}}
						tplUrl="getOfficeCostManagementChangeExportTpl"
						fieldsUrl="getOfficeCostManagementChangeTplItems"
						saveUrl="saveOfficeCostManagementChangeExportTpls"
						exportUrl="exportOfficeCostManagementExport"
						method="export"
						parame={{ ExportType: 'officecostmanagement.export', id: selectedIds, ...filter }}
						cRef={productsRef}
					/>
				</Auth>
			</div>
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				loading={loading}
				className="askforleave-table"
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				onChange={onChangeTable}
				scroll={{ x: 1650 }}
			>
				<Column title="业务编号" dataIndex="id" fixed="left" />
				<Column title="费用类别" dataIndex="costClassCn" />
				<Column title="费用类别具体名称" dataIndex="costClassName" />
				<Column title="公司主体" dataIndex="companyName" />
				<Column title="付款方式" dataIndex="payWayName" />
				<Column title="收款单位" dataIndex="payee" />
				<Column title="开户行" dataIndex="bankName" />
				<Column title="银行账号" dataIndex="bankAccount" />
				<Column title="付款金额" dataIndex="money" />
				<Column title="申请人" dataIndex="applyStaffName" />
				<Column title="状态" dataIndex="busCodeName" />
				<Column title="申请时间" dataIndex="addTime" />
				<Column title="操作" key="oprate" fixed="right" width={100} render={(text, record) => {
					return <>
						<div>
							<Link to={`${match.path}/details?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>详情</Link>
						</div>
						{record.editFlag && <div>
							<Link to={`${match.path}/apply?id=${record.id}`}>编辑</Link>
						</div>}
						{record.reAddFlag && <div>
							<Link to={`${match.path}/apply?id=${record.id}&type=1`}>再次申请</Link>
						</div>}
						{record.deleteFlag && <div>
							<Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record)}>
								<a>删除</a>
							</Popconfirm>
						</div>}
					</>
				}} />
			</Table>
		</>
	)
}

export default Form.create()(CostManagement)