/**
 * 模块名称: 日常办公模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Document from './Document'
import OfficeWork from './OfficeWork'
import System from './System'
import CostManagement from './CostManagement'
import AddOfficeWorkItem from './module/AddOfficeWorkItem'
import OfficeWorkDetail from './module/OfficeWorkDetail'
import CostManagementApply from './module/CostManagementApply'
import CostManagementDetails from './module/CostManagementDetails'
import Details from './module/Details'
import './assets/style.scss'

const DailyWorkIndex = ({ match, history }) => {
  return (
    <div>
      <Switch>
        <Route path={match.path + '/document'} exact component={Document} />
        <Route path={match.path + '/procedure'} exact component={OfficeWork} />
        <Route path={match.path + '/rule'} exact component={System} />
        <Route path={match.path + '/costManagement'} exact component={CostManagement} />
        <Route path={match.path + '/costManagement/apply'} component={CostManagementApply} />
        <Route path={match.path + '/costManagement/details'} component={CostManagementDetails} />
        <Route path={match.path + '/procedure/addffficeworkitem'} component={AddOfficeWorkItem} />
        <Route path={match.path + '/procedure/officeworkdetail'} component={OfficeWorkDetail} />
        <Route path={match.path + '/document/details'} component={Details} />
        <Route component={Document} />
      </Switch>
    </div>
  )
}

export default DailyWorkIndex