/**
 * 模块名称: 到款管理
 * @author wangchanghong@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
import Details from './Details'

const Refund = ({ match, history }) => {

  return (
    <div>
      <Switch> 
        <Route path={match.path + '/detail'} component={Details} />
        <Route component={Main} />
      </Switch>
    </div>
  )
}

export default Refund