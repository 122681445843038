/**
 * 模块名称: 找回密码
 * @author zhuyan@372163.com
 */
import React, { Component } from 'react'
import './assets/style.scss'
import { Link } from 'react-router-dom'
import api from '@/api'
import EmailForm from './EmailForm'
import MobileForm from './MobileForm'
import { Steps, Icon, Tabs, Form } from 'antd'

import SetPass from './SetPass'

class forget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmNum: false, //控制确认模块
      setPsd: true, // 控制设置
      finish: true, //控制完成
      Imgsrc: '', //图形url
      ImgKey: '', //图形key
      account: '',
      acctype: '',
      pasVal: '',
      repasVal: '',
      showFlag: ''
    }

    api.setLoginCheckType()
      .then(res => {
        let flag = 0
        if (res.length >= 2) {
          flag = 2
        } else if (res[0] === '0') {
          flag = 1
        }
        this.setState({
          showFlag: flag
        })
      })
      .catch()
  }

  //账号或手机下一步
  nextStep = () => {
    this.setState({
      confirmNum: true,
      setPsd: false,
      finish: true
    })
  }

  //图形验证码
  getCaptcha = () => {
    api.getCaptcha({
      type: 1
    }, true)
      .then(res => {
        this.setState({
          Imgsrc: res.imageCode,
          ImgKey: res.imageKey
        })
      })
      .catch()
  }

  // 点击验证码刷新
  replaceCode = () => {
    this.getCaptcha()
  }

  componentDidMount() {
    this.getCaptcha()
  }

  emailValFn(elText, type) {
    this.setState({
      account: elText,
      acctype: type
    })
  }

  stateChange(confirmNum, setPsd, finish) {
    this.setState({
      confirmNum: confirmNum,
      setPsd: setPsd,
      finish: finish
    })
  }

  mobilelValFn(moText, type) {
    this.setState({
      account: moText,
      acctype: type
    })
  }

  render() {
    const { Step } = Steps
    const { TabPane } = Tabs
    //const { getFieldDecorator } = this.props.form
    const { Imgsrc, ImgKey, confirmNum, setPsd, acctype, account } = this.state
    return (
      <section className="forget-body">
        {/* 背景 */}
        <section className="forget-body-bg">
          {/* 底部 */}
          <section className="footer">
            <div className="footer-content">
              <p>版权所有：北京全时天地在线网络信息股份有限公司　京ICP备09035321号　京公网安备11010502030777号</p>
            </div>
          </section>
        </section>
        {/* 确认账号 */}
        <div className="one-body" hidden={confirmNum}>
          <div className="one-content">
            <header className="one_header">
              <Steps size="small" current={0}>
                <Step title="确认账号" />
                <Step title="设置密码" />
                <Step title="设置完成" />
              </Steps>
            </header>
            <div className="one-main">
              <Tabs defaultActiveKey="1">
                {
                  this.state.showFlag === 2 || this.state.showFlag === 1 ? <TabPane tab="邮箱找回" key="1" className="yxzh">
                    <EmailForm
                      Imgsrc={Imgsrc}
                      ImgKey={ImgKey}
                      replaceCode={this.replaceCode}
                      getCaptcha={this.getCaptcha}
                      nextStep={this.nextStep}
                      emailValFn={this.emailValFn.bind(this)}
                    />
                  </TabPane> : null
                }
                {
                  this.state.showFlag === 2 || this.state.showFlag === 0 ? <TabPane tab="手机号码找回" key="2">
                    <MobileForm Imgsrc={Imgsrc}
                      ImgKey={ImgKey}
                      replaceCode={this.replaceCode}
                      getCaptcha={this.getCaptcha}
                      nextStep={this.nextStep}
                      mobilelValFn={this.mobilelValFn.bind(this)}
                    />
                  </TabPane> : null
                }
              </Tabs>,
            </div>
          </div>
        </div>

        {/* 设置密码 */}
        <section className="one-body" hidden={setPsd}>
          <div className="one-content" >
            <header className="one_header">
              <Steps size="small" current={1}>
                <Step title="确认账号" />
                <Step title="设置密码" />
                <Step title="设置完成" />
              </Steps>
            </header>
            <SetPass
              acctype={acctype}
              account={account}
              stateChange={this.stateChange.bind(this)}
            />
          </div>
        </section>

        {/* 设置完成 */}
        <section className="one-body" hidden={this.state.finish}>
          <div className="one-content">
            <header className="one_header">
              <Steps size="small" current={2}>
                <Step title="确认账号" />
                <Step title="设置密码" />
                <Step title="设置完成" />
              </Steps>
            </header>
            <div className="one-main one-win">
              <div>
                <Icon type="check-circle" theme="filled" style={{ fontSize: '73px', color: '#52c41a' }} />
              </div>
              <div className="one-win-text">登录密码设置成功</div>
              <div className="one-win-again">请重新<Link to="/">登录</Link></div>
            </div>
          </div>
        </section>
      </section>
    )
  }
}

export default Form.create({ name: 'normal_login' })(forget)