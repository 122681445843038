/**
 * 模块名称: 日常办公 => 费用管理 => 详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Descriptions,
  Spin,
  Icon,
  message,
  Steps,
  Button,
  Modal,
  Input,
  Form,
  Upload
} from 'antd'
import api from '@/api'
import { useSelector } from 'react-redux'
import { upLoadModule } from '@/utils/common'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'
import '../assets/style.scss'

const { Step } = Steps
const dItem = Descriptions.item
const {Dragger} = Upload

let areaTxt = '', result = ''

const CostManagementDetails = (props) => {
  const { history, location } = props
  const { getFieldDecorator } = props.form
  const draggerRef = useRef(null)
  const [detail, setDetail] = useState({})
  const [flow, setFlow] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [pageId, setPageId] = useState('')
  const [auditRecord, setAuditRecord] = useState([])
  const [loading, setLoading] = useState(false)
  const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
  const [files, setFiles] = useState([])
  const [receipt, setReceipt] = useState([])
  //修改
	const [reviseVisible, setReviseVisible] = useState(false)
	const [fileList, setFileList] = useState([])

  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

  useEffect(() => {
    let plaintext = parseSearch(location.search).sign
    let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

    if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
      areaTxt = ''
      result = ''
      if (location.search) {
        setPageId(parseSearch(location.search).id)
        getOfficeCostManagementDetail(parseSearch(location.search).id)
      } else {
        history.push({
          pathname: '/office/costManagement'
        })
      }
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  //获取详情
  const getOfficeCostManagementDetail = (id) => {
    api.getOfficeCostManagementDetail({ id: id, type: 0 }).then(res => {
      if (parseSearch(location.search).msgId) {
        api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
      }
      if (JSON.stringify(res) !== "[]") {
        let data = Object.assign({}, res)
        if (data.status === 0) data.current = 0
        if (data.status === 1) data.current = 1
        if (data.status === 2) data.current = 2
        if (data.status === 3) data.current = 3
        data.attachments && setFiles(JSON.parse(data.attachments))
        // console.log(JSON.parse(data.attachments))
        data.receipt && setReceipt(JSON.parse(data.receipt))
        setDetail(data)
        let auditFlow = data.procedureStatusList
        auditFlow.forEach((item, index, self) => {
          if (item.persons && item.persons.length) {
            self[index].persons = item.persons.join('，')
          }
        })
        setFlow(auditFlow)
        data.procedureLogList && setAuditRecord(data.procedureLogList)
        if (data.buttonAuthList && JSON.stringify(data.buttonAuthList) !== '[]') {
          setAuditAuth(true)
          setHasChargerPower(data.buttonAuthList.hasChargerPower)
          setReplenish(data.buttonAuthList.replenish)
          setReturnUp(data.buttonAuthList.return_up)
          setRejectMsgRequire(data.buttonAuthList.reject_msg_require)
        } else {
          setAuditAuth(false)
          setHasChargerPower(false)
          setReplenish(false)
          setReturnUp(false)
          setRejectMsgRequire(false)
        }
        // setOldData(data.oldData)
      } else {
        setDetail({})
        setFlow([])
        setAuditRecord([])
      }
      setLoading(false)
    })
  }

  //点击4个审批的按钮
  const leaveOutAudit = (no) => {
    areaTxt = ''
    result = no
    if (no == 0) {
      if (detail.luruhetongFlag) {
        setResPlaceholder('请输入审批意见')
        setModalVisible(true)
      } else {
        setResPlaceholder('请输入审批意见')
        setModalVisible(true)
      }

    } else if (no == 1) {
      //审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
      setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    }
    // else {
    //   api.setOfficeCostManagementAudit({
    //     id: pageId,
    //     type: result,
    //     remark: areaTxt
    //   }).then(res => {
    //     //todo 转上级或者补充资料 返回列表页
    //     let timer = setTimeout(() => {
    //       history.push({
    //         pathname: '/office/costManagement'
    //       })
    //       clearTimeout(timer)
    //     }, 1000)
    //   })
    // }
  }

  const oprateHandleOk = () => {
    if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {
      message.warning('请输入至少1个字符')
      return
    }
    let params = {
      id: pageId,
      type: result,
      remark: areaTxt
    }
    api.setOfficeCostManagementAudit(params).then(res => {
      message.success('审批成功')
      setModalVisible(false)
      getOfficeCostManagementDetail(pageId)
    })
  }

  //取消弹窗
  const oprateHandleCancel = () => {
    setModalVisible(false)
  }

  //同意的样式
  const agreeNode = () => {
    return result === 0 ? <>
      通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </> : result === 1 ? <>驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" /></> :
      <>转上级</>
  }

  //审批意见
  const getAreaTxt = (e) => {
    areaTxt = e.target.value.trim()
  }

  //确认修改按钮
	const handleOk = () => {
		let uploadList = []
		for (let i = 0, k = fileList.length; i < k; i++) {
			if (fileList[i].status != 'removed') {
			uploadList.push({
				url: fileList[i].url,
				name: fileList[i].name
			})
			}
		}
		api.setOfficeCostManagementEditPayee({
			id: pageId,
      bankName: detail.bankName,
      bankAccount: detail.bankAccount,
			receipt: JSON.stringify(uploadList)
		}).then(res => {
			message.success('修改成功')
			setReviseVisible(false)
			setFileList([])
			getOfficeCostManagementDetail(pageId)
		}).catch(err => console.log(err))
	}

  // 文件上传
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: ".zip, .pdf, .jpg, .png, .xls, .xlsx",
		limitNum: 20
	})

  //退回列表页
  const onGoBack = () => {
    history.push({ pathname: '/office/costManagement', state: { goBack: 1 } })
  }

  return (
    <>
      <Spin spinning={loading}>
        {
          parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
            position: 'absolute',
            right: 0,
            top: '-20px'
          }}>返回上一页</Button>
        }
        <div style={{ padding: '5px 0' }}>
          <div>
            <div style={{ fontSize: '14px', marginBottom: '5px', color: '#000' }}>
              业务编号：<span style={{ fontSize: '16px' }}>{detail.id}</span>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="cost-management-type">
          <div className="cost-management-type-title">当前状态 <Button type="primary" style={{ marginLeft: '20px' }}>{detail.busCodeName}</Button></div>
          <div>{detail.busCode == 4 ? `终止原因：${detail.failReason}` : ''}</div>
          {/* <div>{detail.busCodeName == '已驳回' ? `审批意见：${detail.approvalComments}` : ''}</div> */}
          <div>
            {detail.busCode == 2 ? <>
              <span>付款凭证：</span>
              {receipt.length > 0 && receipt.map((v, i) => <a style={{ marginRight: '15px' }} key={i} href={v.url} target="_blank" rel="noopener noreferrer">{v.name} </a>)}
              {detail.canEditReceipt == 1 && <a style={{ marginLeft: '15px' }} onClick={() => setReviseVisible(true)}>修改</a>}
              <Modal
								title="修改"
								visible={reviseVisible}
								onOk={handleOk}
								onCancel={() => {
									setReviseVisible(false)
									setFileList([])
								}}
								destroyOnClose={true}
							>
								<div className="dragger-box" ref={draggerRef}>
									<Dragger
										{...uploadFiles}
									>
										<p className="ant-upload-drag-icon">
											<Icon type="inbox" />
										</p>
										<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
										<p className="ant-upload-hint">
											支持扩展名：xls、xlsx、png、jpg、pdf、zip 单个文件大小不超过5M，不超过20个附件。
										</p>
									</Dragger>
								</div>
							</Modal>
            </> : ''}
          </div>
          <div>{detail.busCode == 4 || detail.busCode == 2 ? `操作人：${detail.operateDepartmentName} ${detail.operateStaffName}` : ''}</div>
        </div>
        <div className="line"></div>
        <div className="step-wrap">
          <div className="contract-list-title">审批进度</div>
          <Steps current={detail.current}>
            {
              flow.map((item, index, self) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status} title={item.nodeName} key={index} description={
                  <>
                    {item.persons && <div title={item.persons}>{item.persons}</div>}
                    <div title={item.statusName}>{item.statusName}</div>
                    <div title={item.time}>{item.time}</div>
                  </>
                } />
              })
            }
          </Steps>
        </div>
        <div className="line"></div>
        <Descriptions title="基本信息" layout="horizontal" column={3} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
          <dItem label="费用类别">{detail.costClassName}</dItem>
          {detail.costClassDetailName ? <dItem label="费用类别具体名称">{detail.costClassDetailName}</dItem> : <dItem></dItem>}
          <dItem></dItem>
          <dItem label="公司主体">{detail.companyName}</dItem>
          <dItem></dItem>
          <dItem></dItem>
          <dItem label="付款方式">{detail.payWayName}</dItem>
          <dItem></dItem>
          <dItem></dItem>
          <dItem label="收款单位">{detail.payee}</dItem>
          <dItem label="开户行"> {detail.bankName}</dItem>
          <dItem label="银行账号">{detail.bankAccount}</dItem>
          <dItem label="付款金额">{detail.money}</dItem>
          <dItem></dItem>
          <dItem></dItem>
          {detail.amountInWords && <dItem label="大写金额">{detail.amountInWords}</dItem>}
          
        </Descriptions>
        <div className="line"></div>
        <Descriptions title="其他信息" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
          <dItem label="附件">
            <>
              {
                files.length > 0 ? files.map((item, i) => {
                  return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                }) : <div>无</div>
              }
            </>
          </dItem>
          <dItem label="备注">{detail.remark ? detail.remark : '无'}</dItem>
        </Descriptions>
        <div className="line"></div>
        <div className="record-list" style={{ marginTop: '10px', marginBottom: '50px' }}>
          <Descriptions title="审批记录" layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
            <dItem label="姓名"></dItem>
            <dItem label="时间"></dItem>
            <dItem label="状态"></dItem>
            <dItem label="审批意见"></dItem>
          </Descriptions>
          {
            auditRecord.map((item, index) => {
              return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
                <dItem label="">{item.userInfo.staffName}</dItem>
                <dItem label="">{item.addTime}</dItem>
                <dItem label="">{item.status}</dItem>
                <dItem label="">{item.chargeMsg}</dItem>
              </Descriptions>
            })
          }
        </div>
        {/* <div className="line"></div> */}
      </Spin>
      <div className="contract-details-btn">
        <div className="contract-details-btns">
          {auditAuth && <div className="btns">
            {/* {replenish && <Button onClick={debounce(() => { leaveOutAudit(3) }, 3000)} className="orange-btn">补充资料</Button>}
            {returnUp && <Button onClick={debounce(() => { leaveOutAudit(2) }, 3000)} className="orange-btn">转上级</Button>} */}
            {hasChargerPower && <Button onClick={() => leaveOutAudit(1)} style={{ marginLeft: '15px' }}>驳回</Button>}
            {hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary" style={{ marginLeft: '15px' }}>通过</Button>}
          </div>}
          <Modal
            title={agreeNode()}
            visible={modalVisible}
            onOk={debounce(() => { oprateHandleOk() }, 3000)}
            onCancel={oprateHandleCancel}
            destroyOnClose={true}
            footer={[
              <Button key="back" onClick={oprateHandleCancel}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => { oprateHandleOk() }, 3000)}>
                确定
              </Button>]}
          >
            <div className="officework-modal-content">
              <span className="label">审批意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default Form.create()(CostManagementDetails)