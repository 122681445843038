/**
 * 模块名称: 文档详情
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from 'api'
import { parseSearch } from 'utils'
import { Tabs, Button, message } from 'antd'
import '../assets/style.scss'
import CryptoJS from 'crypto-js'

const { TabPane } = Tabs

const Details = (props) => {
  const { history } = props
  const [data, setData] = useState({
    title: '',
    list: []
  })
  const search = parseSearch(props.location.search)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.onDocManageEditDocRow({ id: search.id }, true).then(data => {
        setData({
          title: data.title,
          list: data.affix
        })
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])



  function callback(key) {
    //console.log(key)
  }

  function affixHandle(data) {
    let url = data.url

    if(data.type == 'csv'){//eslint-disable-line
      return <>
        <div style={{textAlign: 'center', marginTop: '30px'}}>对不起，该格式文件不支持在线预览，请下载后查看~</div>
      </>
    }

    if (data.type !== 'pdf') {
      url = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
    }

    return <embed height="680" width="100%" src={url} />
  }

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={callback} animated={false}>
        {data.list.map((item, index) =>(
          <TabPane tab={`附件${index+1}`} key={index}>
            {affixHandle(item)}
            <div className="download-btn-wrap">
              <Button onClick={history.goBack}>关闭</Button>
              <a href={item.url} target="_blank" className="ant-btn ant-btn-primary" rel="noopener noreferrer">下载附件</a>
            </div>
          </TabPane>
        ))}
      </Tabs>
    </>
  )
}

export default Details