/**
 * 模块名称: 日常办公审批
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from 'api'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  Drawer,
  DatePicker,
  Input,
  Button,
  Select,
  Alert,
  Icon,
  Popconfirm,
  message,
  Spin
} from 'antd'
import AddModule from './module/AddModule'
import Auth from 'components/AuthMiddleware'
import { parseSearch } from 'utils'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}
//模板管理页码参数
let modulePageSize = 10
let moduleCurrentPage = 1

let intervalRef = {}

const OfficeWork = props => {

  const { getFieldDecorator, validateFields, resetFields } = props.form
  const { history, match, location } = props

  const [statusList, setStatusList] = useState([])
  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [moduleListVisible, setModuleListVisible] = useState(false)
  const [moduleList, setModuleList] = useState([])
  const [moduleLoading, setModuleLoading] = useState(true)
  const [moduleCount, setModuleCount] = useState(0)
  
  const [moduleAddVisible, setModuleAddVisible] = useState(false)
  const [addModuleLoading, setAddModuleLoading] = useState(true)
  const [editRowId, setEditRowId] = useState('')
  const [moduleName, setModuleName] = useState('添加分类')

  const [authList, setAuthList] = useState([])//按钮权限

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getOfficeWorkGetList()
    getPageAuth()
    return () => {
      for(let i in intervalRef){//eslint-disable-line
        clearTimeout(intervalRef[i])
      }
    }
  }, [])
  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取日常办公模板列表
  const getOfficeWorkGetList = () => {
    intervalRef.current = setTimeout(() => {
      let params = Object.assign({type: 0}, searchParamsTmp)
      api.getOfficeWorkGetList(params).then(res => {
        setList(res.list)
        setCount(res.count)
        setLoading(false)
        clearTimeout(intervalRef.current)
      }).catch(() => { setLoading(false) })
    }, 500)
  }

  //获取状态列表
  const getStatusList = () => {
    api.getOfficeWorkGetPageStatus().then(res => {
      setStatusList(res.list)
    })
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getOfficeWorkGetList()
  }
  //提交
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      if(vals !== undefined){
        if(vals.docTitle !== undefined)searchParamsTmp.title = vals.docTitle
        if(vals.applicant !== undefined)searchParamsTmp.name = vals.applicant
        if(vals.status !== undefined)searchParamsTmp.status = vals.status
        if(vals.inpicker !== undefined){
          searchParamsTmp.sTime = vals.inpicker ? vals.inpicker[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.eTime = vals.inpicker ? vals.inpicker[1].format('YYYY-MM-DD') : ''
        }
        if(vals.onpicker !== undefined){
          searchParamsTmp.sAuditTime = vals.onpicker ? vals.onpicker[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.eAuditTime = vals.onpicker ? vals.onpicker[1].format('YYYY-MM-DD') : ''
        }
      }
      if(!err){
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getOfficeWorkGetList()
      }
    })
  }
  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getOfficeWorkGetList()
  }
  //取消申请
  const onCancelApply = (id) => {
    api.onOfficeWorkCancel({id: id}).then(res => {
      message.success('取消申请成功')
      getOfficeWorkGetList()
    })
  }

  //打开模板管理列表
  const openModuleLis = () => {
    setModuleListVisible(true)
    getOfficeTemplateGetList()
  }
  //获取模板列表
  const getOfficeTemplateGetList = () => {
    let params = {
      page: moduleCurrentPage,
      limit: modulePageSize
    }
    api.getOfficeTemplateGetList(params).then(res => {
      setModuleList(res.list)
      setModuleCount(res.count)
      setModuleLoading(false)
    }).catch(() => { setModuleLoading(false) })
  }
  //打开添加模板抽屉
  const addModule =  () => {
    setEditRowId('')
    setModuleName('添加模板')
    setModuleAddVisible(true)
  }
  //模板管理点击分页
  const onChangeModuleTable = (pagination, filters, sorter) => {
    moduleCurrentPage = pagination.current
    modulePageSize = pagination.pageSize
    getOfficeTemplateGetList()
  }
  //模板管理编辑
  const onEditModule = (record) => {
    setEditRowId(record.id)
    setModuleName('修改模板')
    setModuleAddVisible(true)
  }
  //删除模板
  const onDelModule = (id) => {
    api.onOfficeTemplateDel({id}, true).then(() => {
      message.success('删除成功')
      getOfficeTemplateGetList()
      getOfficeWorkGetList()
    })
  }
  //跳转 新建发起申请 建立审批表item
  const addOfficeWorkItem = () => {
    let data = {}
    history.push({
      pathname: match.path + '/addffficeworkitem',
      query: data
    })
  }

  return (
   <>
    <div className="search-doc-wrap search-office-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <div className="form-box">
            <FormItem label="标题">
              {getFieldDecorator('docTitle', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="状态">
              {getFieldDecorator('status')(<Select
                placeholder="请选择" 
                onDropdownVisibleChange={bool => bool && getStatusList()}
              >
                {
                  statusList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="申请人">
              {getFieldDecorator('applicant', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="申请日期">
              {getFieldDecorator('inpicker', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="审批日期">
              {getFieldDecorator('onpicker', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div>
        <div className="add-wrap">
          <Auth auths={authList} code="daily-office-manage">
            <Button type="primary" icon="plus" onClick={openModuleLis} title="管理模板">管理模板</Button>
          </Auth>
          <Auth auths={authList} code="operate">
            <Button type="primary" icon="plus" onClick={addOfficeWorkItem} title="发起申请" style={{marginLeft: '15px'}}>发起申请</Button>
          </Auth>
        </div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"  
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="序号" dataIndex="number" />
          <Column title="审批ID" dataIndex="id" />
          <Column title="标题" dataIndex="title" render={(text, record) =>
            <>
              {
                <Link to={`${match.path}/officeworkdetail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
              }
            </>
          } />     
          <Column title="状态" dataIndex="statusName" />
          <Column title="申请时间" dataIndex="addTime" />
          <Column title="审批时间" dataIndex="auditTime" />
          <Column title="申请人" dataIndex="userName" />
          <Column title="操作"
            key="set" 
            fixed="right"
            width={100}
            render={(text, record) =>
              <>
                {
                  record.cancelFlag && <Popconfirm title="请确认是否取消该申请" cancelText="取消" okText="继续" onConfirm={() => onCancelApply(record.id)}>
                    <Button className="operate-icon" type="delete">取消申请</Button>
                  </Popconfirm>
                }
              </>
            }
          />
        </Table>
      </div>
      <Drawer
        title={
          <div style={{position: "relative"}}>
            模板管理
            <Icon onClick={addModule} type="plus-circle" style={{position: "absolute", right: "40px", top: "5px", cursor: "pointer"}} title="添加模板" />
          </div>
        }
        width={900}
        onClose={() => setModuleListVisible(false)}
        visible={moduleListVisible}
        destroyOnClose={true}
        className="office-module-drawer"
        zIndex="1000"
      >
        <Table
          dataSource={moduleList}
          rowKey="id"  
          className="sort-table"
          loading={moduleLoading}
          pagination={{
            pageSize: modulePageSize,
            total: moduleCount,
            current: moduleCurrentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeModuleTable}
        >
          <Column title="名称" dataIndex="name" />
          <Column title="创建人" dataIndex="userName" />
          <Column title="创建时间" dataIndex="addTime" />
          <Column title="操作"
            key="set" 
            render={(text, record) =>
              <>
                <Icon className="operate-icon" type="edit" onClick={() => onEditModule(record)} />
                {' '}
                <Popconfirm title="请确认是否删除当前模板，删除后不可恢复" cancelText="取消" okText="确认" onConfirm={() => onDelModule(record.id)}>
                  <Icon className="operate-icon" type="delete" />
                </Popconfirm>
              </>
            }
          />
        </Table>
      </Drawer>
      <Drawer
        title={moduleName}
        width={840}
        zIndex="1001"
        destroyOnClose={true}
        onClose={() => setModuleAddVisible(false)}
        visible={moduleAddVisible}
        className="add-module-drawer"
      >
        <Spin spinning={addModuleLoading}>
          <AddModule
            setVisible={setModuleAddVisible}
            getOfficeTemplateGetList={getOfficeTemplateGetList}
            editRowId={editRowId}
            setAddModuleLoading={setAddModuleLoading}
          />
        </Spin>
      </Drawer>
   </> 
  )
}

export default Form.create()(OfficeWork)
