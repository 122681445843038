/**
 * 模块名称: 邮箱找回密码
 * @author zhuyan@372163.com
 */
import React, { Component } from 'react'
// import './assets/style.scss'
import api from '@/api'
import { Icon, Button, Input, Form, message } from 'antd'

class emailForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      codeVal: '', //图形值
      emailVal: '', //账号值
      emailCodeVal: '', //账号验证码
      timeValue: "获取验证码",
      codeDisabled: true, //获取验证码btn
      emailFlag: false, //帐号值是否正确
      codeFlag: false, //是否正确
      timeoutFlag: false, //是否在倒计时
    }
  }
  inputEmaiChange = (e) => {
    this.setState({ emailVal: e.target.value })
  }

  // 邮箱是否存在
  blurEmailInput = () => {
    let email = this.state.emailVal
    if (email.trim() !== '') {
      api.checkAccount({
        account: this.state.emailVal
      }, true)
        .then(res => {
          this.setState({ emailFlag: true })
          if (this.state.emailFlag && this.state.codeFlag && !this.state.timeoutFlag) {
            this.setState({ codeDisabled: false })
          }
        })
        .catch(err => {
          this.setState({
            emailFlag: false,
            codeDisabled: true
          })
        })
    }
  }

  inputCodeValChange = (e) => {
    this.setState({ codeVal: e.target.value })
  }

  // 验证图形验证码
  inputCodeValBlur = (e) => {
    let { codeVal } = this.state
    if (codeVal.trim() !== '') {
      api.checkImageCode({
        imageKey: this.props.ImgKey,
        code: codeVal
      }, true)
        .then(res => {
          this.setState({ codeFlag: true })
          if (this.state.emailFlag && this.state.codeFlag && !this.state.timeoutFlag) {
            this.setState({ codeDisabled: false })
          }
        })
        .catch(err => {
          this.setState({
            codeVal: '',
            codeFlag: false,
            codeDisabled: true
          })
          this.props.getCaptcha()
        })
    }
  }

  // 获取邮件验证码
  getEmailCode = () => {
    let { emailVal } = this.state
    api.getEmailCode({
      email: emailVal
    }, true)
      .then(res => {
        this.timeoutInterval()
      })
      .catch(err => {

      })
  }

  inputEmailCodeVal = (e) => {
    this.setState({ emailCodeVal: e.target.value })
  }

  // 倒计时
  timeoutInterval() {
    this.setState({ codeDisabled: true })
    let sumTime = 60
    let interval = setInterval(() => {
      if (sumTime > 0) {
        sumTime--
        this.setState({
          timeoutFlag: true,
          timeValue: "获取验证码(" + sumTime + "S)"
        })
      } else {
        clearInterval(interval)
        this.setState({
          timeoutFlag: false,
          codeDisabled: false,
          timeValue: "获取验证码"
        })
      }
    }, 1000)
  }

  // 邮箱下一步
  handleSubmitNextStep = e => {
    let { emailVal, emailCodeVal } = this.state, type = 2
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (emailCodeVal.trim() === '') {
          message.error('验证码不能为空')
          return
        }
        api.checkCode({
          account: emailVal,
          type: 1,
          code: emailCodeVal
        }, true)
          .then(res => {
            this.props.nextStep()
            this.props.emailValFn(emailVal, type)
          })
          .catch(err => {
            // this.props.nextStep()  测试使用
            this.props.getCaptcha()
            this.setState({
              codeVal: '',
              emailCodeVal: '',
              codeFlag: false,
              codeDisabled: true
            })
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { emailVal, codeVal, emailCodeVal, timeValue, codeDisabled } = this.state
    return (
      <Form onSubmit={this.handleSubmitNextStep}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{
              required: true, message: '请输入邮箱'
            }],
          })(
            <Input
              size="large"
              prefix={<Icon type="mail" value={emailVal} style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='请输入邮箱'
              onChange={this.inputEmaiChange}
              onBlur={this.blurEmailInput}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('code', {
            rules: [{ required: true, whitespace: true, message: '请输入验证码' }],
          })(
            <span className="one-yx">
              <Input
                size="large"
                className="one_Input"
                prefix={<Icon type="safety-certificate" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='验证码'
                value={codeVal}
                onChange={this.inputCodeValChange}
                onBlur={this.inputCodeValBlur}
              />
              <span style={{cursor: 'pointer'}}><img className="one-yx-code" src={this.props.Imgsrc} alt="" onClick={this.props.replaceCode} /></span>
            </span>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('email-code', {
            rules: [{ required: true, message: '请输入邮件验证码' }],
          })(
            <span className="one-yx">
              <Input
                size="large"
                className="one_Input"
                prefix={<Icon type="message" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='邮件验证码'
                value={emailCodeVal}
                onChange={this.inputEmailCodeVal}
              />
              <Button size="large" type="primary" onClick={this.getEmailCode} disabled={codeDisabled}>{timeValue}</Button>
            </span>
          )}
        </Form.Item>
        <Form.Item>
          <span className="one-btn">
            <Button size="large" type="primary" htmlType="submit">下一步</Button>
          </span>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create({ name: 'normal_login' })(emailForm)
