/**
 * 模块名称: 日常办公审批 => 发起申请
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import api from 'api'
import {
	Form,
	Input,
	Select,
	Upload,
	message,
	Icon,
	Row,
	Col
} from 'antd'
import BtnGroup from 'components/BtnGroup'
import { upLoadModule } from 'utils/common'
import Editor from 'wangeditor'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload
let disabled = true

const AddOfficeWorkItem = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue } = props.form

	const { editRowId, history } = props

	const [fileList, setFileList] = useState([])
	const [auditList, setAuditList] = useState([])
	const [copyToList, setCopyToList] = useState([])

	const [title, setTitle] = useState('')
	const [content, setContent] = useState('')
	const [audit, setAudit] = useState(undefined)
	const [copyTo, setCopyTo] = useState(undefined)

	const [moduleList, setModuleList] = useState([])
	const [moduleChecked, setModuleChecked] = useState([])//eslint-disable-line

	const draggerRef = useRef(null)

	const [editorHtml, setEditorHtml] = useState('')//eslint-disable-line
	const [editorText, setEditorText] = useState('')//eslint-disable-line
	const contentRef = useRef(null)

	useEffect(() => {
		disabled = true
		onOfficeWorkHandle()
		initEditor()
	}, [])

	//富文本
	const initEditor = () => {
		let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
		dealEditor(editor)
		editor.create()
	}
	//富文本数据配置
	const dealEditor = (editor) => {
		editor.customConfig.zIndex = 100
		editor.customConfig.showLinkImg = false
		editor.customConfig.uploadImgServer = '/api/File/upload'
		editor.customConfig.uploadFileName = 'file'
		editor.customConfig.uploadImgHeaders = {
			token: localStorage.getItem('token')
		}
		// 自定义菜单配置
		editor.customConfig.menus = [
			'head',  // 标题
			'bold',  // 粗体
			'fontSize',  // 字号
			'fontName',  // 字体
			'italic',  // 斜体
			'underline',  // 下划线
			'strikeThrough',  // 删除线
			'foreColor',  // 文字颜色
			'backColor',  // 背景颜色
			'link',  // 插入链接
			'list',  // 列表
			'justify',  // 对齐方式
			'quote',  // 引用
			'image',  // 插入图片
			'table',  // 表格
			'code',  // 插入代码
			'undo',  // 撤销
			'redo'  // 重复
		]
		//插入视频 : 'video'
		//'emoticon',  // 表情
		editor.customConfig.onchange = (html) => {
			//console.log(html)
			setEditorHtml(html)
			setEditorText(editor.txt.text())
			setFieldsValue({
				content: html
			})
		}
		editor.customConfig.uploadImgHooks = {
			before(xhr, editor, files) {
				//console.log(xhr, editor, files)
			},
			success(xhr, editor, result) {
				//console.log(xhr, editor, result)
			},
			fail(xhr, editor, result) {
				// console.log(xhr, editor, result)
			},
			error(xhr, editor, result) {
				// console.log(xhr, editor, result)
			},
			timeout(xhr, editor) {
				// console.log(xhr, editor)
			},
			customInsert(insertImg, result, editor) {
				//console.log(insertImg, result, editor)
				var url = result.data.full
				insertImg(url)
			}
		}
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 2 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 22 },
		}
	}
	//根据是否传值过来editRowId判断是上传文档还是编辑文档
	const onOfficeWorkHandle = () => {
		let params = {}
		if (editRowId) {
			params.id = editRowId
			api.onOfficeWorkHandle(params).then(res => {
				//console.log(res)
				setTitle(res.title)
				setContent(res.content)
				setAudit(res.audit)
				setCopyTo(res.copyTo)
				getAuditList()
				getCopyToList()
				if (res.affix.length) {
					res.affix.forEach((item, index) => {
						fileList.push({
							status: 'done',
							uid: index,
							name: item.name,
							url: item.url
						})
					})
					setFileList(res.affix.map((item, index) => ({...item, uid: index})))
				}
				//console.log(fileList)

			}).catch(() => { })
		}
	}

	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		if (disabled) {
			disabled = false
			let timer = setTimeout(() => {
				disabled = true
				clearTimeout(timer)
			}, 1000)
		} else {
			message.error('请不要频繁点击')
			return
		}
		validateFields((err, vals) => {
			if (vals.audit !== undefined && vals.audit.length > 10) {
				message.warning('审批人最多选择10个人')
				return
			}
			if (vals.copyTo !== undefined && vals.copyTo.length > 10) {
				message.warning('抄送人最多选择10个人')
				return
			}
			let params = Object.assign({}, vals)
			let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
			params.affix = uploadList
			if (editRowId) params.id = editRowId
			if (!err) {
				api.onOfficeWorkHandle(params).then(res => {
					if (!editRowId) {
						message.success('新增审批成功')
					} else {
						message.success('编辑审批成功')
					}
					history.push({
						pathname: '/office/procedure'
					})
				})
			}
		})
	}
	//取消发起申请
	const onCancel = () => {
		history.push({
			pathname: '/office/procedure'
		})
	}

	// 审批人
	const getAuditList = (val) => {
		if (val) {
			api.onOfficeWorkGetStaffByName({ name: val }).then(res => {
				setAuditList(res.list)
			})
		} else {
			setAuditList([])
		}
	}
	// 操作审批人
	const handleChangeAuditList = (val) => {
		setAudit(val)
	}
	// 抄送人
	const getCopyToList = (val) => {
		if (val) {
			api.onOfficeWorkGetStaffByName({ name: val }).then(res => {
				setCopyToList(res.list)
			})
		} else {
			setCopyToList([])
		}
	}
	// 操作抄送人
	const handleChangeCopyToList = (val) => {
		setCopyTo(val)
	}
	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .pptx, .PDF, .doc, .docx, .csv, .xlsx, .zip, .rar',
		allowSizeType: 6
  })

	//选择模板
	const getModuleList = () => {
		api.getOfficeTemplateGetList().then(res => {
			setModuleList(res.list)
		})
	}
	//选中模板
	const getModuleListItem = (id) => {
		api.getOfficeTemplateGetRow({ id: id }).then(res => {
			setContent(res.content)
			setFieldsValue({
				content: res.content
			})
			setAuditList(res.audit)
			setCopyToList(res.copyTo)
			//回显富文本内容
			let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
			dealEditor(editor)
			editor.create()
			editor.txt.html(res.content)

			if (res.audit.length) {
				let auditArr = []
				res.audit.forEach(item => {
					auditArr.push(item.id)
				})
				setAudit(auditArr)
			} else {
				setAudit([])
			}
			if (res.copyTo.length) {
				let copyToArr = []
				res.copyTo.forEach(item => {
					copyToArr.push(item.id)
				})
				setCopyTo(copyToArr)
			} else {
				setCopyTo([])
			}
		})
	}
	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout} style={{ width: "70%" }}>
				<FormItem label="标题">
					{getFieldDecorator('title', {
						initialValue: title,
						rules: [{ required: true, message: '请输入标题' }]
					})(<Input placeholder="请输入标题" autoComplete="off" />)}
				</FormItem>
				<FormItem label="模板">
					{getFieldDecorator('moduleChecked', {
						initialValue: moduleChecked,
						rules: [{ required: false, message: '请选择模板' }]
					})(
						<Select
							placeholder="请选择模板"
							showArrow
							style={{ width: '100%' }}
							onDropdownVisibleChange={bool => bool && getModuleList()}
							onChange={getModuleListItem}
						>
							{moduleList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</FormItem>
				<FormItem label="内容">
					{getFieldDecorator('content', {
						initialValue: content,
						rules: [{ required: true, message: '请输入备注' }]
					})(<div className="wangEditor-container" ref={contentRef}></div>)}
				</FormItem>
				<FormItem label="附件">
					<div className="dragger-box" ref={draggerRef}>
						<Dragger
							{...uploadFiles}
						>
							<p className="ant-upload-drag-icon">
								<Icon type="inbox" />
							</p>
							<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
							<p className="ant-upload-hint">
								附件支持格式：GIF、PNG、JPG、pptx、PDF、doc、docx、csv、xlsx、zip、rar 支持添加多个附件，单个附件大小不超过50M，文件数量不超过5个。
							</p>
						</Dragger>
					</div>
				</FormItem>
				<FormItem label=" " colon={false}>
					<Row gutter={8}>
						<Col span={8}>
							<FormItem label="审批人">
								{getFieldDecorator('audit', {
									initialValue: audit,
									rules: [{ required: true, message: '请选择审批人' }]
								})(
									<Select
										mode="multiple"
										showSearch
										showArrow
										placeholder="请选择审批人"
										style={{ width: '100%' }}
										defaultActiveFirstOption={false}
										onSearch={getAuditList}
										onChange={handleChangeAuditList}
										notFoundContent={null}
										filterOption={false}
									>
										{auditList.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
									</Select>
								)}
							</FormItem>
						</Col>
						<Col span={4}></Col>
						<Col span={8}>
							<FormItem label="抄送人">
								{getFieldDecorator('copyTo', {
									initialValue: copyTo,
									rules: [{ required: false, message: '请选择抄送人' }]
								})(
									<Select
										mode="multiple"
										showSearch
										showArrow
										placeholder="请选择抄送人"
										style={{ width: '100%' }}
										onSearch={getCopyToList}
										onChange={handleChangeCopyToList}
										notFoundContent={null}
										filterOption={false}
									>
										{copyToList.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
									</Select>
								)}
							</FormItem>
						</Col>
					</Row>

				</FormItem>
				<BtnGroup onCancel={onCancel} />
			</Form>
		</>
	)
}

export default Form.create()(AddOfficeWorkItem)