/**
 * 模块名称: 公司制度
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  Table,
  Form,
  Drawer,
  DatePicker,
  Input,
  Button,
  Alert,
  Icon,
  Popconfirm,
  message,
  Spin,
  Tag,
  Tooltip
} from 'antd'
import AddCorpRule from './module/AddCorpRule'
import CorpFilter from 'components/CorpFilter'
import Auth from 'components/AuthMiddleware'
import { parseSearch } from 'utils'

const { Column } = Table
const FormItem = Form.Item
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}

let intervalRef = {}

const System = props => {

  const { getFieldDecorator, validateFields, resetFields } = props.form

  const { history, match, location } = props

  const [branchIds, setBranchIds] = useState([])

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [editRowId, setEditRowId] = useState('')
  const [addCorpRulesLoading, setAddCorpRulesLoading] = useState(true)
  const [addDrawerName, setAddDrawerName] = useState('新建发布')

  const [authList, setAuthList] = useState([])//按钮权限

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getCorpRuleRuleList()
    getPageAuth()
    return () => {
      for(let i in intervalRef){//eslint-disable-line
        clearTimeout(intervalRef[i])
      }
    }
  }, [])
  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }
  //点击选择所属公司
  const onChangeCorp = (data) => {
    //console.log('onChangeCorp', data)
    searchParamsTmp.page = currentPage = 1
    history.replace(match.path)
    let arr = []
    data.forEach((item, index) => {
      arr.push(item.id)
    })
    setBranchIds(arr)
    if(arr.length){
      searchParamsTmp.corpId = arr.join(',')
    }else{
      delete searchParamsTmp.corpId
    }
    getCorpRuleRuleList()
  }
  //新建发布
  const onOpenAddCorpRule = () => {
    setEditRowId('')
    setAddDrawerName('新建发布')
    setVisible(true)
  }
  //获取制度列表
  const getCorpRuleRuleList = () => {
    setLoading(true)
    intervalRef.current = setTimeout(() => {
      api.getCorpRuleRuleList(searchParamsTmp).then(res => {
        setLoading(false)
        setCount(res.count)
        setList(res.list)
        clearTimeout(intervalRef.current)
      }).catch(() => { setLoading(false) })
    }, 500)
  }
  //编辑
  const onEdit = (record) => {
    //console.log(record)
    setEditRowId(record.id)
    setAddDrawerName('编辑制度')
    setVisible(true)
  }
  //删除
  const onDelete = (id) => {
    api.onCorpRuleDel({id}, true).then(() => {
      message.success('删除成功')
      //resetFields()
      getCorpRuleRuleList()
    })
  }
  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getCorpRuleRuleList()
  }
  //表单提交
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      if(vals !== undefined){
        if(vals.docTitle !== undefined)searchParamsTmp.title = vals.docTitle
        if(vals.docCreator !== undefined)searchParamsTmp.name = vals.docCreator
        if(vals.docType !== undefined)searchParamsTmp.type = vals.docType
        if(vals.inpicker !== undefined){
          searchParamsTmp.sTime = vals.inpicker ? vals.inpicker[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.eTime = vals.inpicker ? vals.inpicker[1].format('YYYY-MM-DD') : ''
        }
      }
      if(!err){
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getCorpRuleRuleList()
      }
    })
  }
  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    if(branchIds.length)searchParamsTmp.corpId = branchIds.join(',')
    getCorpRuleRuleList()
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <FormItem label="标题">
            {getFieldDecorator('docTitle', {
            })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="创建人">
            {getFieldDecorator('docCreator', {
            })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="创建时间">
            {getFieldDecorator('inpicker', {
            })(<RangePicker allowClear={false} />)}
          </FormItem>
          <FormItem className="btns" label=" ">
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div>
        <div className="add-wrap">
          <Auth auths={authList} code="operate">
            <Button type="primary" icon="plus" onClick={onOpenAddCorpRule} title="新建发布">新建发布</Button>
          </Auth>
        </div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"  
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          // scroll={{x: 1400}}
          onChange={onChangeTable}
        >
          <Column title="标题" dataIndex="title" />
          <Column title="推送范围" dataIndex="corpName" render={(text, record) =>
            (
              <span>
                {
                  record.corpName.map((item, index) => {
                    return <Tag key={index} style={{marginBottom: "3px"}}>{item}</Tag>
                  })
                }
              </span>
            )
          } />
          <Column title="创建人" dataIndex="userName" width={100} />     
          <Column title="创建时间" dataIndex="addTime" width={160} />
          <Column title="操作"
            key="set" 
            fixed="right"
            width={100}
            render={(text, record) =>
              <>
                <Tooltip title="编辑" placement="bottom">
                  <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
                </Tooltip>
                {' '}
                <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Tooltip title="删除" placement="bottom">
                    <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                  </Tooltip>
                </Popconfirm>
              </>
            }
          />
        </Table>
        <Drawer
          title={addDrawerName}
          width={800}
          onClose={() => setVisible(false)}
          visible={visible}
          destroyOnClose={true}
        >
          <Spin spinning={addCorpRulesLoading}>
            <AddCorpRule
              setVisible={setVisible}
              getCorpRuleRuleList={getCorpRuleRuleList}
              editRowId={editRowId}
              setAddCorpRulesLoading={setAddCorpRulesLoading}
            />
          </Spin>
        </Drawer>
      </div>
    </>
  )      
}

export default Form.create()(System)
