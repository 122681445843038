/**
 * 模块名称: 日常办公 => 费用管理 => 申请页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Select,
  Input,
  DatePicker,
  InputNumber,
  Form,
  message,
  Row,
  Col,
  Upload,
  Icon,
  Tooltip,
  TreeSelect
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import moment from 'moment'
import '../assets/style.scss'

const { Option } = Select
const { TreeNode } = TreeSelect
const { Dragger } = Upload
const FormItem = Form.Item
const { RangePicker } = DatePicker

var temp

const CostManagementApply = (props) => {
  const { location, history } = props
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form

  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [costClassId, setCostClassId] = useState('')
  const [companyList, setCompanyList] = useState([])
  const [oaIdType, setOaIdType] = useState('')
  const [oaidName, setOaidName] = useState('')
  const [productsText, setProductsText] = useState('')
  const [oaidValue, setOaidValue] = useState('')
  const [projectsResults, setProjectsResults] = useState([])
  const [costClassList, setCostClassList] = useState([])//获取费用类别
  const [payWayList, setPayWayList] = useState([])//获取付款方式
  const [moneyDx, setMoneyDx] = useState('')

  const search = parseSearch(location.search)

  useEffect(() => {
    common()
    if (search.id) {
      api.getOfficeCostManagementDetail({ id: search.id, type: 1 }, true).then(res => {
        const fileLists = JSON.parse(res.attachments)
        setCostClassId(res.costClass)
        if (res.costClass !== 1) {
          setFieldsValue({
            costClassName: res.costClassDetailName
          })
        }
        setFieldsValue({
          costClass: res.costClass,
          companyId: res.companyId,
          payWay: res.payWay,
          payee: res.payee,
          bankName: res.bankName,
          bankAccount: res.bankAccount,
          money: res.money,
          remark: res.remark
        })
        if (fileLists.length) {
          fileLists.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(fileLists.map((item, index) => ({ ...item, uid: index })))
        }
      })
    }
  }, [])

  //获取公共接口
  const common = () => {
    api.getOfficeCostManagementCostClass().then(res => setCostClassList(res)).catch(err => console.log(err))//获取费用类别
    api.getOfficeCostManagementPayWay().then(res => setPayWayList(res)).catch(err => console.log(err))//获取付款方式
    api.getHeadCompanyList({ limit: global.paramsLimit, type: 'feiyong' }).then(data => setCompanyList(data))//公司主体
  }

  //添加
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      if (!err) {
        api.setOfficeCostManagementApply({
          id: search.id && !search.type ? search.id : '',
          costClass: vals.costClass,
          costClassName: costClassId !== 1 ? vals.costClassName : '',
          companyId: vals.companyId,
          payWay: vals.payWay,
          payee: vals.payee,
          bankName: vals.bankName,
          bankAccount: vals.bankAccount,
          money: vals.money,
          remark: vals.remark,
          attachments: JSON.stringify(uploadList)
        }).then(res => {
          // console.log(res.message)
          if (res.status !== 'success') {
            return message.error(res.message)
          }
          history.push('/office/costManagement')
        })
      } else {
        message.error('请填写必填内容')
      }
    })
  }

  //取消返回列表页
  const onCancel = () => history.push('/office/costManagement')

  // 文件上传 
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.gif, .png, .jpg, .PDF, .zip, .xls, .xlsx, .csv',
    limitNum: 20,
    allowSizeType: 3
  })

  //费用类别下拉获取具体名称
  const onSelectCostClass = (values, option) => {
    setCostClassId(values)
  }

  //付款金额
  const onChangeMoney = (e) => {
    if (e.target.value) {
      setMoneyDx(DX(e.target.value))
    } else {
      setMoneyDx('')
    }
    
  }

  //转大写
  const DX = (tranvalue) => {
    try {
      var i = 1;
      var dw2 = new Array("", "万", "亿");//大单位
      var dw1 = new Array("拾", "佰", "仟");//小单位
      var dw = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖");//整数部分用
      //以下是小写转换成大写显示在合计大写的文本框中     
      //分离整数与小数
      var source = splits(tranvalue);
      var num = source[0];
      var dig = source[1];

      //转换整数部分
      var k1 = 0;//计小单位
      var k2 = 0;//计大单位
      var sum = 0;
      var str = "";
      var len = source[0].length;//整数的长度
      for (i = 1; i <= len; i++) {
        var n = source[0].charAt(len - i);//取得某个位数上的数字
        var bn = 0;
        if (len - i - 1 >= 0) {
          bn = source[0].charAt(len - i - 1);//取得某个位数前一位上的数字
        }
        sum = sum + Number(n);
        if (sum != 0) {
          str = dw[Number(n)].concat(str);//取得该数字对应的大写数字，并插入到str字符串的前面
          if (n == '0') sum = 0;
        }
        if (len - i - 1 >= 0) {//在数字范围内
          if (k1 != 3) {//加小单位
            if (bn != 0) {
              str = dw1[k1].concat(str);
            }
            k1++;
          } else {//不加小单位，加大单位
            k1 = 0;
            temp = str.charAt(0);
            if (temp == "万" || temp == "亿")//若大单位前没有数字则舍去大单位
              str = str.substr(1, str.length - 1);
            str = dw2[k2].concat(str);
            sum = 0;
          }
        }
        if (k1 == 3)//小单位到千则大单位进一
        { k2++; }
      }

      //转换小数部分
      var strdig = "";
      if (dig != "") {
        var n = dig.charAt(0);
        if (n != 0) {
          strdig += dw[Number(n)] + "角";//加数字
        }
        var n = dig.charAt(1);
        if (n != 0) {
          strdig += dw[Number(n)] + "分";//加数字
        }
      }
      if (strdig !== '') {
        if (str !== '') {
          str += "元" + strdig;
        } else {
          str += strdig;
        }

      } else {
        str += "元" + strdig + '整'
      }

    } catch (e) {
      return "0元";
    }
    return str;
  }

  //拆分整数与小数
  function splits(tranvalue) {
    var value = new Array('', '');
    temp = tranvalue.split(".");
    for (var i = 0; i < temp.length; i++) {
      value[i] = temp[i];
    }
    return value;
  }

  return (
    <>
      <div className="cost-management-apply">
        <h1 className="cost-management-apply-title">{search.id && !search.type ? '费用驳回重提申请' : '费用申请'}</h1>
        <div className="line"></div>
        <Form onSubmit={handleSubmit}>
          <h3 className="cost-management-apply-tit">基本信息</h3>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="费用类别">
                {getFieldDecorator('costClass', {
                  rules: [{ required: true, message: '请输入和选择' }]
                })(<Select placeholder="请选择" dropdownMatchSelectWidth={false} onSelect={onSelectCostClass}>
                  {
                    costClassList.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            {
              costClassId !== 1 ? <Col span={6}>
                <FormItem label="费用类别具体名称">
                  {getFieldDecorator('costClassName', {
                    rules: [{ required: true, message: '请输入' }]
                  })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col> : null
            }
            <Col span={6}>
              <FormItem label="公司主体">
                {getFieldDecorator('companyId', {
                  rules: [{ required: true, message: '请输入和选择' }]
                })(<Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                  {
                    companyList.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="付款方式">
                {getFieldDecorator('payWay', {
                  rules: [{ required: true, message: '请输入和选择' }]
                })(<Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                  {
                    payWayList.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="收款单位">
                {getFieldDecorator('payee', {
                  // rules: [{ required: true, message: '请输入' }]
                })(<Input placeholder="请输入" />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="开户行">
                {getFieldDecorator('bankName', {
                  // rules: [{ required: true, message: '请输入' }]
                })(<Input placeholder="请输入" />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="银行账号">
                {getFieldDecorator('bankAccount', {
                  // rules: [
                  //   { required: true, message: '请输入' }, 
                  //   {
                  //     pattern: /^-?[0-9]*(\.[0-9]*)?$/, message: '请输入正确的格式'
                  //   }]
                })(<Input placeholder="请输入" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="付款金额">
                {getFieldDecorator('money', {
                  rules: [{ required: true, message: '请输入' }, {
                    pattern: /^-?[0-9]*(\.[0-9]*)?$/, message: '请输入正确的格式'
                  }]
                })(<Input placeholder="请输入" onChange={onChangeMoney} />)}
              </FormItem>
            </Col>
          </Row>
          {
            moneyDx &&  <Row gutter={24}>
              <Col span={6}>
                <div>大写金额：{moneyDx}</div>
              </Col>
            </Row>
          }
          <Row gutter={24}>
            <Col span={16}>
              <FormItem label="附件">
                <div className="dragger-box" ref={draggerRef}>
                  <Dragger
                    {...uploadFiles}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p className="ant-upload-hint">
                      附件支持格式：GIF、PNG、JPG、PDF、zip、xls、xlsx、csv  支持添加多个附件，单个附件大小不超过10M，文件数量不超过5个。
                    </p>
                  </Dragger>
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={16}>
              <FormItem label="备注">
                {getFieldDecorator('remark')(
                  <Input.TextArea placeholder="请输入" maxLength={100} />
                )}
              </FormItem>
            </Col>
          </Row>
          <BtnGroup confirmName="提交" onCancel={onCancel} />
        </Form>
      </div>
    </>
  )
}

export default Form.create()(CostManagementApply)