/**
 * 模块名称: 忘记密码
 * @author zhuyan@372163.com
 */
import React, { Component } from 'react';
import './assets/style.scss'
import api from '@/api'
import { Icon, Button, Input, Form, message } from 'antd'

export class SetPass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pasVal: '',
      repasVal: ''
    }
  }

  inputpasValChange = (e) => {
    this.setState({ pasVal: e.target.value })
  }
  inputrepasValChange = (e) => {
    this.setState({ repasVal: e.target.value })
  }

  // 设置密码下一步
  setNextStep = e => {
    let { pasVal, repasVal } = this.state
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const reg = /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])^[0-9A-Za-z\S]{7,20}$/
        if (!reg.test(values.password)) {
          message.error('登录密码不符合规则，请重新输入')
          return
        }
        if (values.password !== values.repassword) {
          message.error('两次密码输入不一致，请重新输入')
          return
        }
        api.findPassword({
          account: this.props.account,
          type: this.props.acctype,
          passWord: pasVal,
          rePassWord: repasVal
        }, true)
          .then(res => {
            this.props.stateChange(true, true, false)
          })
          .catch(err => {
            // this.props.nextStep()
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { pasVal, repasVal } = this.state
    return (
      <div className="one-main">
        <Form onSubmit={this.setNextStep}>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{
                required: true, message: '密码不能为空'
              },
                // { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{7,30}$/, message: '密码须同时含大、小写字母和数字，长度7~30位'}
              ],
            })(
              <span>
                <Input.Password
                  size="large"
                  value={pasVal}
                  onChange={this.inputpasValChange}
                  prefix={<Icon type="shopping"
                    style={{ color: 'rgba(0,0,0,.25)', display: 'inline-block' }} />}
                  placeholder='登录密码'
                />
                <span className="show-rule"> 注：7-20位字符，不能为纯数字，不能含有空格，必须同时包含大小写字母、数字。</span>
              </span>
            )}
          </Form.Item>
          <Form.Item>
            <span className="one_gap">
              {getFieldDecorator('repassword', {
                rules: [{
                  required: true, message: '密码不能为空'
                },
                  //  {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{7,30}$/, message: '密码须同时含大、小写字母和数字，长度7~30位'}
                ],
              })(
                <span>
                  <Input.Password
                    size="large"
                    value={repasVal}
                    onChange={this.inputrepasValChange}
                    prefix={<Icon type="shopping" style={{ color: 'rgba(0,0,0,.25)', display: 'inline-block' }} />}
                    placeholder='再次输入登录密码'
                  />
                </span>
              )}
            </span>
          </Form.Item>
          <Form.Item>
            <span className="one-btn">
              <Button size="large" type="primary" htmlType="submit" >下一步</Button>
            </span>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export default Form.create({ name: 'normal_login' })(SetPass)
