/**
 * 模块名称: 日常办公审批右侧弹窗 => 添加模板
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import api from 'api'
import {
	Form,
	Input,
	Select,
	message
} from 'antd'
import BtnGroup from 'components/BtnGroup'
import Editor from 'wangeditor'

const FormItem = Form.Item
const Option = Select.Option

const AddModule = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue } = props.form

	const { setVisible, getOfficeTemplateGetList, editRowId, setAddModuleLoading } = props

	const [saveLoading, setSaveLoading] = useState(false)

	const [name, setName] = useState('')
	const [content, setContent] = useState('请输入内容')
	const [audit, setAudit] = useState(undefined)
	const [copyTo, setCopyTo] = useState(undefined)
	const [auditList, setAuditList] = useState([])
	const [copyToList, setCopyToList] = useState([])

	const [editorHtml, setEditorHtml] = useState('')//eslint-disable-line
	const [editorText, setEditorText] = useState('')//eslint-disable-line
	const contentRef = useRef(null)

	useEffect(() => {
		setAddModuleLoading(false)
		getOfficeTemplateGetRow()
		initEditor()
	}, [])

	//富文本
	const initEditor = () => {
		let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
		dealEditor(editor)
		editor.create()
	}
	//富文本数据配置
	const dealEditor = (editor) => {
		editor.customConfig.showLinkImg = false
		editor.customConfig.uploadImgServer = '/api/File/upload'
		editor.customConfig.uploadFileName = 'file'
		editor.customConfig.uploadImgHeaders = {
			token: localStorage.getItem('token')
		}
		// 自定义菜单配置
		editor.customConfig.menus = [
			'head',  // 标题
			'bold',  // 粗体
			'fontSize',  // 字号
			'fontName',  // 字体
			'italic',  // 斜体
			'underline',  // 下划线
			'strikeThrough',  // 删除线
			'foreColor',  // 文字颜色
			'backColor',  // 背景颜色
			'link',  // 插入链接
			'list',  // 列表
			'justify',  // 对齐方式
			'quote',  // 引用
			'image',  // 插入图片
			'table',  // 表格
			'code',  // 插入代码
			'undo',  // 撤销
			'redo'  // 重复
		]
		//插入视频 : 'video'
		//'emoticon',  // 表情
		editor.customConfig.onchange = (html) => {
			//console.log(html)
			setEditorHtml(html)
			setEditorText(editor.txt.text())
			setFieldsValue({
				content: html
			})
		}
		editor.customConfig.uploadImgHooks = {
			before(xhr, editor, files) {
				//console.log(xhr, editor, files)
			},
			success(xhr, editor, result) {
				//console.log(xhr, editor, result)
			},
			fail(xhr, editor, result) {
				// console.log(xhr, editor, result)
			},
			error(xhr, editor, result) {
				// console.log(xhr, editor, result)
			},
			timeout(xhr, editor) {
				// console.log(xhr, editor)
			},
			customInsert(insertImg, result, editor) {
				//console.log(insertImg, result, editor)
				var url = result.data.full
				insertImg(url)
			}
		}
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 3 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 21 },
		}
	}
	//根据是否传值过来editRowId判断是上传文档还是编辑文档
	const getOfficeTemplateGetRow = () => {
		let params = {}
		if (editRowId) {
			setAddModuleLoading(true)
			params.id = editRowId
			let auditArr = [], copyToArr = []
			api.getOfficeTemplateGetRow(params).then(res => {
				let data = Object.assign({}, res)
				setName(res.name)
				setContent(res.content)

				//回显富文本内容
				let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
				dealEditor(editor)
				editor.create()
				editor.txt.html(res.content)

				data.audit.length && data.audit.forEach((item) => {
					auditArr.push(item.id)
				})
				data.copyTo.length && data.copyTo.forEach((item) => {
					copyToArr.push(item.id)
				})
				setAuditList(data.audit)
				setCopyToList(data.copyTo)
				setAudit(auditArr)
				setCopyTo(copyToArr)

				let timer = setTimeout(() => {
					setAddModuleLoading(false)
					clearTimeout(timer)
				}, 500)

			}).catch(() => { setAddModuleLoading(false) })
		}
	}

	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		setSaveLoading(true)
		validateFields((err, vals) => {
			//console.log(vals)

			let params = Object.assign({}, vals)
			if (editRowId) params.id = editRowId
			if (!err) {
				api.onOfficeTemplateHandle(params).then(res => {
					message.success('操作成功')
					setVisible(false)
					setSaveLoading(false)
					getOfficeTemplateGetList()
				}).catch(() => {
					setSaveLoading(false)
				})
			}
		})
	}

	// 审批人
	const getAuditList = (val) => {
		if (val) {
			api.onOfficeWorkGetStaffByName({ name: val }).then(res => {
				setAuditList(res.list)
			})
		} else {
			setAuditList([])
		}
	}
	// 操作审批人
	const handleChangeAuditList = (val) => {
		setAudit(val)
	}
	// 抄送人
	const getCopyToList = (val) => {
		if (val) {
			api.onOfficeWorkGetStaffByName({ name: val }).then(res => {
				setCopyToList(res.list)
			})
		} else {
			setCopyToList([])
		}
	}
	// 操作抄送人
	const handleChangeCopyToList = (val) => {
		setCopyTo(val)
	}

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<FormItem label="模板名称">
					{getFieldDecorator('name', {
						initialValue: name,
						rules: [{ required: true, message: '请输入模板名称' }]
					})(<Input autoComplete="off" placeholder="请输入模板名称" />)}
				</FormItem>
				{/* <FormItem label="模板内容">
					{getFieldDecorator('content', {initialValue: content,
						rules: [{ required: true, message: '请输入模板内容' }]
					})(<Input.TextArea placeholder="请输入模板内容" />)}
				</FormItem> */}
				<FormItem label="模板内容">
					{getFieldDecorator('content', {
						initialValue: content,
						rules: [{ required: true, message: '请输入模板内容' }]
					})(<div className="wangEditor-container" ref={contentRef}></div>)}
				</FormItem>
				<FormItem label="审批人">
					{getFieldDecorator('audit', {
						initialValue: audit,
						rules: [{ required: false, message: '请选择审批人' }]
					})(
						<Select
							mode="multiple"
							showSearch
							showArrow
							placeholder="请选择审批人"
							style={{ width: '100%' }}
							defaultActiveFirstOption={false}
							onSearch={getAuditList}
							onChange={handleChangeAuditList}
							notFoundContent={null}
							filterOption={false}
						>
							{auditList.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
						</Select>
					)}
				</FormItem>
				<FormItem label="抄送人">
					{getFieldDecorator('copyTo', {
						initialValue: copyTo,
						rules: [{ required: false, message: '请选择抄送人' }]
					})(
						<Select
							mode="multiple"
							showSearch
							showArrow
							placeholder="请选择抄送人"
							style={{ width: '100%' }}
							onSearch={getCopyToList}
							onChange={handleChangeCopyToList}
							notFoundContent={null}
							filterOption={false}
						>
							{copyToList.map(item => <Option key={item.id} value={item.id}>{item.staffName}</Option>)}
						</Select>
					)}
				</FormItem>
				<BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
			</Form>
		</>
	)
}
export default Form.create()(AddModule)