/**
 * 模块名称: 上传文档右侧弹窗
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from 'api'
import {
	Form,
	Input,
	Select,
	Upload,
	message,
	Icon,
	Row,
	Col,
	Button
} from 'antd'
import BtnGroup from 'components/BtnGroup'
import { upLoadModule } from 'utils/common'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload
let disabled = true

const UploadDocDrawer = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue } = props.form

	const { setVisible, getDocManageDocList, setTypeVisible, editRowId, setUploadLoading } = props

	const [fileList, setFileList] = useState([])
	const [choiceList, setChoiceList] = useState([])
	const [saveLoading, setSaveLoading] = useState(false)//eslint-disable-line
	const [docTypeList, setDocTypeList] = useState([])

	const [title, setTitle] = useState('')
	const [type, setType] = useState(undefined)
	const [scope, setScope] = useState([])
	const [fileListError, setFileListError] = useState(false)

	const draggerRef = useRef(null)

	useEffect(() => {
		disabled = true
		setUploadLoading(false)
		onDocManageEditDocRow()
	}, [])

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
	}
	//根据是否传值过来editRowId判断是上传文档还是编辑文档
	const onDocManageEditDocRow = () => {
		let params = {}
		if (editRowId) {
			setUploadLoading(true)
			params.id = editRowId
			api.onDocManageEditDocRow(params).then(res => {
				//console.log(res)
				setTitle(res.title)
				getDocTypeList()
				api.getCorp({ limit: global.paramsLimit }).then(data => {
					let list = []
					data.list.forEach((item, index, arr) => {
						if (item.isValid === 1) list.push(item)
					})
					let arr = [...list]
					arr.forEach((item, index, self) => {
						self[index].label = item.name
						self[index].value = item.id
					})
					setChoiceList(arr)
					let ids = []
					arr.forEach(item => {
						ids.push(item.id)
					})
					let scopeRes = res.scope.filter(item => ids.some(e => e === item))
					setScope(scopeRes)
				})
				setType(res.type)
				if (res.affix.length) {
					res.affix.forEach((item, index) => {
						fileList.push({
							status: 'done',
							uid: index,
							name: item.name,
							url: item.url
						})
					})
					setFileList(res.affix.map((item, index) => ({...item, uid: index})))
				}
				//console.log(fileList)
				let timer = setTimeout(() => {
					setUploadLoading(false)
					clearTimeout(timer)
				}, 500)

			}).catch(() => { setUploadLoading(false) })
		}
	}
	//获取文档类型列表
	const getDocTypeList = () => {
		api.getDocTypePageTypeData({'isValid':1}).then(res => {
			setDocTypeList(res.list)
		})
	}
	//选择文档类型
	const getDocType = () => {

	}
	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		if (disabled) {
			disabled = false
			let timer = setTimeout(() => {
				disabled = true
				clearTimeout(timer)
			}, 1000)
		} else {
			message.error('请不要频繁点击')
			return
		}
		validateFields((err, vals) => {
			//console.log(vals)
			if (!fileList.length) {
				setFileListError(true)
				//message.warning('请上传附件')
				return
			}
			let params = Object.assign({}, vals)
			let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
			params.affix = uploadList
			if (!params.affix.length) {
				setFileListError(true)
				//message.warning('请上传附件')
				return
			}
			setFileListError(false)
			if (editRowId) params.id = editRowId
			if (!err) {
				api.onDocManageDocHandle(params).then(res => {
					message.success('操作成功')
					setVisible(false)
					getDocManageDocList()
				})
			}
		})
	}

	// 推送范围
	const getCorp = () => {
		api.getCorp({ limit: global.paramsLimit }).then(res => {
			let list = []
			res.list.forEach((item, index, arr) => {
				if (item.isValid === 1) list.push(item)
			})
			let arr = [...list]
			arr.forEach((item, index, self) => {
				self[index].label = item.name
				self[index].value = item.id
			})
			setChoiceList(arr)
		})
	}

	//全选推送范围
	const selCorpAll = () => {
		api.getCorp({ limit: global.paramsLimit }).then(res => {
			let list = [], idArr = []
			res.list.forEach(item => {
				if (item.isValid === 1) {
					list.push(item)
					idArr.push(item.id)
				}
			})
			let arr = [].concat(list)
			arr.forEach((item, index, self) => {
				self[index].label = item.name
				self[index].value = item.id
			})
			setChoiceList(arr)
			setFieldsValue({
				'scope': idArr
			})
		})
	}
	//清空推送范围
	const clearCorpAll = () => {
		setFieldsValue({
			'scope': []
		})
	}

	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.pptx, .PDF, .doc, .docx, .csv, .xlsx',
		allowSizeType: 6
  })

	//添加分类
	const addType = () => {
		//setVisible(false)
		setTypeVisible(true)
	}

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<FormItem label="标题">
					{getFieldDecorator('title', {
						initialValue: title,
						rules: [{ required: true, message: '请输入标题' }]
					})(<Input autoComplete="off" placeholder="请输入标题" maxLength={30} />)}
				</FormItem>
				<FormItem label="文档类型">
					<Row gutter={8}>
						<Col span={20}>
							{getFieldDecorator('type', {
								initialValue: type,
								rules: [{ required: true, message: '请输入内容' }]
							})(
								<Select
									placeholder="请选择"
									style={{ width: '100%' }}
									onDropdownVisibleChange={bool => bool && getDocTypeList()}
									onChange={getDocType}
								>
									{docTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
								</Select>
							)}
						</Col>
						<Col span={4}>
							<Button htmlType="button" type="primary" onClick={addType}>添加分类</Button>
						</Col>
					</Row>
				</FormItem>
				<FormItem label="附件" required>
					<div className="dragger-box" ref={draggerRef}>
						<Dragger
							{...uploadFiles}
						>
							<p className="ant-upload-drag-icon">
								<Icon type="inbox" />
							</p>
							<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
							<p className="ant-upload-hint">
								附件支持格式pptx、PDF、doc、docx、csv、xlsx  支持添加多个附件，单个附件大小不超过50M，文件数量不超过5个。
							</p>
						</Dragger>
						{fileListError && <div className="ant-form-extra" style={{ color: '#f5222d', transition: 'color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)' }}>请上传附件</div>}
					</div>
				</FormItem>
				<FormItem label="推送范围">
					<Row gutter={8}>
						<Col span={18}>
							{getFieldDecorator('scope', {
								initialValue: scope,
								rules: [{ required: true, message: '请选推送范围' }]
							})(
								<Select
									placeholder="请选择推送范围"
									mode="multiple"
									style={{ width: '100%' }}
									onDropdownVisibleChange={bool => bool && getCorp()}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{choiceList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
								</Select>
							)}
						</Col>
						<Col span={3}>
							<Button type="primary" onClick={selCorpAll}>全选</Button>
						</Col>
						<Col span={3}>
							<Button onClick={clearCorpAll}>清空</Button>
						</Col>
					</Row>
				</FormItem>
				<BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
			</Form>
		</>
	)
}
export default Form.create()(UploadDocDrawer)