/**
 * 模块名称: 到款列表
 * @author wangchanghong@372163.com
 */

import React, { useState } from 'react'
import api from '@/api'
import {
    Form,
    Input,
    Checkbox,
    Modal, 
    message,
    Icon
} from 'antd'
const { confirm } = Modal;

const FormItem = Form.Item

const Renkuan = (props) => {
    const form = props.form;
    const { id, renKuanVisible, setRenKuanVisible, getList} = props
    const { getFieldDecorator} = form

    const [renkuanSubmitLoading, setRenkuanSubmitLoading] = useState(false)
    const [checked, setChecked] = useState(false)

    const showConfirm = (callback) => {
        confirm({
            title: '确认',
            content: (<><span style={{color:'#f5222d'}}>标记该笔到款为"财务认款"</span><span style={{color:'#B2B2B2',fontSize:'12px'}}> (注：财务认款后，该笔到款将不能再次标记和被认领)</span></>),
            onOk() {
                callback()
            },
        });
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        }
    }


    const onRenkuanOk = () => {
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            showConfirm(() => {
                if (!checked) {
                    return message.warning('点击确认必须勾选')
                }
                setRenkuanSubmitLoading(true)
                api.fundMoneyBackFinancialRecognition({id,mnbkType:1,mnbDetail:values.mnbDetail})
                    .then(() => {
                        message.success('标记成功')
                        form.resetFields()
                        setRenKuanVisible(false);
                        setChecked(false)
                        getList();
                    }).finally(() => {
                    setRenkuanSubmitLoading(false)
                    setChecked(false)
                })
            })

        });
    }

    const onRenkuanCancel = () => {
        form.resetFields();
        setRenKuanVisible(false)
        setChecked(false)
    }

    //选择变化的时候的回调
    const onChange = (e) => {
        // console.log(e.target.checked)
        setChecked(e.target.checked)
    }


    return (
        <Modal
            title={<><Icon type="info-circle" style={{color: '#faaf19'}} /> 请注意！</>}
            visible={renKuanVisible}
            onOk={onRenkuanOk}
            confirmLoading={renkuanSubmitLoading}
            onCancel={onRenkuanCancel}
            cancelText="返回"
        >
            <Form {...formItemLayout}>
                <FormItem>
                    {getFieldDecorator('status', {
                        valuePropName:'checked',
                        DItems: [{ required: true, message: '请先选择"财务认款"' }]
                    })(<Checkbox onChange={onChange}>
                        <span style={{color:'#f5222d'}}>标记该笔到款为"财务认款"</span><span style={{color:'#B2B2B2',fontSize:'12px'}}> (注：财务认款后，该笔到款将不能再次标记和被认领)</span>
                    </Checkbox>)}
                </FormItem>
                <FormItem label={<span style={{color:'#f5222d'}}>财务认款说明</span>}>
                    {getFieldDecorator('mnbDetail', {
                        rules: [{ required: true, message: '请输入财务认款说明' }]
                    })(
                        <Input placeholder="财务认款时必填" />
                    )}
                </FormItem>
            </Form>
        </Modal>
    )
}

export default Form.create()(Renkuan)